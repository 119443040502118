import { Form, Label, Button, Header, Segment, Comment } from 'semantic-ui-react'
import { Component } from 'react'
import { connect } from "react-redux";
import axios from 'axios'
import moment from 'moment';

class TestimonialsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      testimonies: [],
      userAvatar: '',
      userName: '',
      userEmail: null,
      testimonyContent: ''
    }
  }

  componentDidUpdate = () => {
    if(this.state.userEmail===null && this.props.user.auth.userEmail) {
        this.setState({
          ...this.state,
          userEmail: this.props.user.auth.userEmail,
          userAvatar: this.props.user.auth.userAvatar,
          userName: this.props.user.auth.userName
        })
    }
  }

  componentWillMount = async() => {
    await this.retrieveTestimonies()
  }

  retrieveTestimonies = async() => {
    let testimonyResponse = await axios.get('/testimony');
    console.log('testimonyResponse', testimonyResponse);
    this.setState({
        ...this.state,
        testimonies: testimonyResponse.data,
        testimonyContent: ''
    })
  }

  handleTextArea = (e) => {
    this.setState({
        ...this.state,
        [e.target.name]: e.target.value,
    })
  }

  addTestimony = async() => {
    let testimony = {};
    testimony.userAvatar = this.state.userAvatar;
    testimony.userEmail = this.state.userEmail;
    testimony.testimonyContent = this.state.testimonyContent;
    testimony.userName = this.state.userName;

    let testimonyResponse = await axios.post('/testimony', testimony);
    await this.retrieveTestimonies();
  }

  render() {
    console.log('props in test', this.props.user.auth);
    console.log('state in test', this.state)
    let testimonies = this.state.testimonies;
    return (
        <Segment padded='very' style={{ marginLeft: '5%', marginTop: '10px', marginRight: '5%'}}>
            <Comment.Group>
                <Header as='h3' dividing>
                    Testimonials
                    <Header.Subheader>
                      Wholesome personal stories where Leon has done more than just drive you to places.
                      {/* <br /><br />
                      In case you have nothing memorable to add, no one can turn down a selfie with Leon. */}
                    </Header.Subheader>
                </Header>

                {testimonies.length > 0 && 
                    Object.keys(testimonies).map(key => (
                        <Comment key={key} style={{ display: 'inline'}}>
                            <Label circular size='large' style={{ display: 'inline', marginRight: '10px'}}>
                                {testimonies[key].userAvatar}
                            </Label>
                            <Comment.Content style={{ display: 'inline'}}>
                                <Comment.Author as='a'>{testimonies[key].userName}</Comment.Author>
                                <Comment.Metadata>
                                <div>{moment(testimonies[key].createdAt).format("Do MMM , hh:mm A")}</div>
                                </Comment.Metadata>
                                <Comment.Text style={{ marginLeft: '15%'}}>{testimonies[key].content}</Comment.Text>
                            </Comment.Content>
                        </Comment>
                    ))}

                <Form reply>
                <Form.TextArea name="testimonyContent" onChange={this.handleTextArea} value={this.state.testimonyContent}/>
                    <Button content='Add' primary onClick={this.addTestimony}/>
                </Form>
            </Comment.Group>
        </Segment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state
  }
}

export default connect(mapStateToProps) (TestimonialsPage);
