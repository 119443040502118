import React, { Component } from 'react'
import { Button, Modal, Icon, Form, Input, Message, Dropdown } from 'semantic-ui-react'
import { fetchUser, createRequest } from '../../actions'
import { connect } from "react-redux";
import firebase from '../../firebase'

//TO-DO add phone number to request data and insert it
//you can add postive and negative messages by maintaining seperate state for positive and negative
class OTPVerificationModal extends React.Component {
    state = {
        modalOpen: false,
        phoneNumber: null,
        message: null,
        verificationCodeSent: false,
        otpEntered: '',
        verified: false,
        parentState: {},
        countryOptions: [],
        label: '',
        country: ''
    };

    componentDidMount = () => {
        var countries = [
            {
              name: "Afghanistan",
              key: "af",
              text: "Afghanistan",
              value: "af",
              flag: "af",
              phoneCode: 93
            },
            {
              name: "Aland Islands",
              key: "ax",
              text: "Aland Islands",
              value: "ax",
              flag: "ax",
              phoneCode: 358
            },
            {
              name: "Albania",
              key: "al",
              text: "Albania",
              value: "al",
              flag: "al",
              phoneCode: 355
            },
            {
              name: "Algeria",
              key: "dz",
              text: "Algeria",
              value: "dz",
              flag: "dz",
              phoneCode: 213
            },
            {
              name: "American Samoa",
              key: "as",
              text: "American Samoa",
              value: "as",
              flag: "as",
              phoneCode: 1684
            },
            {
              name: "Andorra",
              key: "ad",
              text: "Andorra",
              value: "ad",
              flag: "ad",
              phoneCode: 376
            },
            {
              name: "Angola",
              key: "ao",
              text: "Angola",
              value: "ao",
              flag: "ao",
              phoneCode: 244
            },
            {
              name: "Anguilla",
              key: "ai",
              text: "Anguilla",
              value: "ai",
              flag: "ai",
              phoneCode: 1264
            },
            {
              name: "Antarctica",
              key: "aq",
              text: "Antarctica",
              value: "aq",
              flag: "aq",
              phoneCode: 672
            },
            {
              name: "Antigua and Barbuda",
              key: "ag",
              text: "Antigua and Barbuda",
              value: "ag",
              flag: "ag",
              phoneCode: 1268
            },
            {
              name: "Argentina",
              key: "ar",
              text: "Argentina",
              value: "ar",
              flag: "ar",
              phoneCode: 54
            },
            {
              name: "Armenia",
              key: "am",
              text: "Armenia",
              value: "am",
              flag: "am",
              phoneCode: 374
            },
            {
              name: "Aruba",
              key: "aw",
              text: "Aruba",
              value: "aw",
              flag: "aw",
              phoneCode: 297
            },
            {
              name: "Australia",
              key: "au",
              text: "Australia",
              value: "au",
              flag: "au",
              phoneCode: 61
            },
            {
              name: "Austria",
              key: "at",
              text: "Austria",
              value: "at",
              flag: "at",
              phoneCode: 43
            },
            {
              name: "Azerbaijan",
              key: "az",
              text: "Azerbaijan",
              value: "az",
              flag: "az",
              phoneCode: 994
            },
            {
              name: "Bahamas",
              key: "bs",
              text: "Bahamas",
              value: "bs",
              flag: "bs",
              phoneCode: 1242
            },
            {
              name: "Bahrain",
              key: "bh",
              text: "Bahrain",
              value: "bh",
              flag: "bh",
              phoneCode: 973
            },
            {
              name: "Bangladesh",
              key: "bd",
              text: "Bangladesh",
              value: "bd",
              flag: "bd",
              phoneCode: 880
            },
            {
              name: "Barbados",
              key: "bb",
              text: "Barbados",
              value: "bb",
              flag: "bb",
              phoneCode: 1246
            },
            {
              name: "Belarus",
              key: "by",
              text: "Belarus",
              value: "by",
              flag: "by",
              phoneCode: 375
            },
            {
              name: "Belgium",
              key: "be",
              text: "Belgium",
              value: "be",
              flag: "be",
              phoneCode: 32
            },
            {
              name: "Belize",
              key: "bz",
              text: "Belize",
              value: "bz",
              flag: "bz",
              phoneCode: 501
            },
            {
              name: "Benin",
              key: "bj",
              text: "Benin",
              value: "bj",
              flag: "bj",
              phoneCode: 229
            },
            {
              name: "Bermuda",
              key: "bm",
              text: "Bermuda",
              value: "bm",
              flag: "bm",
              phoneCode: 1441
            },
            {
              name: "Bhutan",
              key: "bt",
              text: "Bhutan",
              value: "bt",
              flag: "bt",
              phoneCode: 975
            },
            {
              name: "Bolivia",
              key: "bo",
              text: "Bolivia",
              value: "bo",
              flag: "bo",
              phoneCode: 591
            },
            {
              name: "Bonaire, Sint Eustatius and Saba",
              key: "bq",
              text: "Bonaire, Sint Eustatius and Saba",
              value: "bq",
              flag: "bq",
              phoneCode: 599
            },
            {
              name: "Bosnia and Herzegovina",
              key: "ba",
              text: "Bosnia and Herzegovina",
              value: "ba",
              flag: "ba",
              phoneCode: 387
            },
            {
              name: "Botswana",
              key: "bw",
              text: "Botswana",
              value: "bw",
              flag: "bw",
              phoneCode: 267
            },
            {
              name: "Bouvet Island",
              key: "bv",
              text: "Bouvet Island",
              value: "bv",
              flag: "bv",
              phoneCode: 55
            },
            {
              name: "Brazil",
              key: "br",
              text: "Brazil",
              value: "br",
              flag: "br",
              phoneCode: 55
            },
            {
              name: "British Indian Ocean Territory",
              key: "io",
              text: "British Indian Ocean Territory",
              value: "io",
              flag: "io",
              phoneCode: 246
            },
            {
              name: "Brunei Darussalam",
              key: "bn",
              text: "Brunei Darussalam",
              value: "bn",
              flag: "bn",
              phoneCode: 673
            },
            {
              name: "Bulgaria",
              key: "bg",
              text: "Bulgaria",
              value: "bg",
              flag: "bg",
              phoneCode: 359
            },
            {
              name: "Burkina Faso",
              key: "bf",
              text: "Burkina Faso",
              value: "bf",
              flag: "bf",
              phoneCode: 226
            },
            {
              name: "Burundi",
              key: "bi",
              text: "Burundi",
              value: "bi",
              flag: "bi",
              phoneCode: 257
            },
            {
              name: "Cambodia",
              key: "kh",
              text: "Cambodia",
              value: "kh",
              flag: "kh",
              phoneCode: 855
            },
            {
              name: "Cameroon",
              key: "cm",
              text: "Cameroon",
              value: "cm",
              flag: "cm",
              phoneCode: 237
            },
            {
              name: "Canada",
              key: "ca",
              text: "Canada",
              value: "ca",
              flag: "ca",
              phoneCode: 1
            },
            {
              name: "Cape Verde",
              key: "cv",
              text: "Cape Verde",
              value: "cv",
              flag: "cv",
              phoneCode: 238
            },
            {
              name: "Cayman Islands",
              key: "ky",
              text: "Cayman Islands",
              value: "ky",
              flag: "ky",
              phoneCode: 1345
            },
            {
              name: "Central African Republic",
              key: "cf",
              text: "Central African Republic",
              value: "cf",
              flag: "cf",
              phoneCode: 236
            },
            {
              name: "Chad",
              key: "td",
              text: "Chad",
              value: "td",
              flag: "td",
              phoneCode: 235
            },
            {
              name: "Chile",
              key: "cl",
              text: "Chile",
              value: "cl",
              flag: "cl",
              phoneCode: 56
            },
            {
              name: "China",
              key: "cn",
              text: "China",
              value: "cn",
              flag: "cn",
              phoneCode: 86
            },
            {
              name: "Christmas Island",
              key: "cx",
              text: "Christmas Island",
              value: "cx",
              flag: "cx",
              phoneCode: 61
            },
            {
              name: "Cocos (Keeling) Islands",
              key: "cc",
              text: "Cocos (Keeling) Islands",
              value: "cc",
              flag: "cc",
              phoneCode: 672
            },
            {
              name: "Colombia",
              key: "co",
              text: "Colombia",
              value: "co",
              flag: "co",
              phoneCode: 57
            },
            {
              name: "Comoros",
              key: "km",
              text: "Comoros",
              value: "km",
              flag: "km",
              phoneCode: 269
            },
            {
              name: "Congo",
              key: "cg",
              text: "Congo",
              value: "cg",
              flag: "cg",
              phoneCode: 242
            },
            {
              name: "Congo, Democratic Republic of the Congo",
              key: "cd",
              text: "Congo, Democratic Republic of the Congo",
              value: "cd",
              flag: "cd",
              phoneCode: 242
            },
            {
              name: "Cook Islands",
              key: "ck",
              text: "Cook Islands",
              value: "ck",
              flag: "ck",
              phoneCode: 682
            },
            {
              name: "Costa Rica",
              key: "cr",
              text: "Costa Rica",
              value: "cr",
              flag: "cr",
              phoneCode: 506
            },
            {
              name: "Cote D'Ivoire",
              key: "ci",
              text: "Cote D'Ivoire",
              value: "ci",
              flag: "ci",
              phoneCode: 225
            },
            {
              name: "Croatia",
              key: "hr",
              text: "Croatia",
              value: "hr",
              flag: "hr",
              phoneCode: 385
            },
            {
              name: "Cuba",
              key: "cu",
              text: "Cuba",
              value: "cu",
              flag: "cu",
              phoneCode: 53
            },
            {
              name: "Curacao",
              key: "cw",
              text: "Curacao",
              value: "cw",
              flag: "cw",
              phoneCode: 599
            },
            {
              name: "Cyprus",
              key: "cy",
              text: "Cyprus",
              value: "cy",
              flag: "cy",
              phoneCode: 357
            },
            {
              name: "Czech Republic",
              key: "cz",
              text: "Czech Republic",
              value: "cz",
              flag: "cz",
              phoneCode: 420
            },
            {
              name: "Denmark",
              key: "dk",
              text: "Denmark",
              value: "dk",
              flag: "dk",
              phoneCode: 45
            },
            {
              name: "Djibouti",
              key: "dj",
              text: "Djibouti",
              value: "dj",
              flag: "dj",
              phoneCode: 253
            },
            {
              name: "Dominica",
              key: "dm",
              text: "Dominica",
              value: "dm",
              flag: "dm",
              phoneCode: 1767
            },
            {
              name: "Dominican Republic",
              key: "do",
              text: "Dominican Republic",
              value: "do",
              flag: "do",
              phoneCode: 1809
            },
            {
              name: "Ecuador",
              key: "ec",
              text: "Ecuador",
              value: "ec",
              flag: "ec",
              phoneCode: 593
            },
            {
              name: "Egypt",
              key: "eg",
              text: "Egypt",
              value: "eg",
              flag: "eg",
              phoneCode: 20
            },
            {
              name: "El Salvador",
              key: "sv",
              text: "El Salvador",
              value: "sv",
              flag: "sv",
              phoneCode: 503
            },
            {
              name: "Equatorial Guinea",
              key: "gq",
              text: "Equatorial Guinea",
              value: "gq",
              flag: "gq",
              phoneCode: 240
            },
            {
              name: "Eritrea",
              key: "er",
              text: "Eritrea",
              value: "er",
              flag: "er",
              phoneCode: 291
            },
            {
              name: "Estonia",
              key: "ee",
              text: "Estonia",
              value: "ee",
              flag: "ee",
              phoneCode: 372
            },
            {
              name: "Ethiopia",
              key: "et",
              text: "Ethiopia",
              value: "et",
              flag: "et",
              phoneCode: 251
            },
            {
              name: "Falkland Islands (Malvinas)",
              key: "fk",
              text: "Falkland Islands (Malvinas)",
              value: "fk",
              flag: "fk",
              phoneCode: 500
            },
            {
              name: "Faroe Islands",
              key: "fo",
              text: "Faroe Islands",
              value: "fo",
              flag: "fo",
              phoneCode: 298
            },
            {
              name: "Fiji",
              key: "fj",
              text: "Fiji",
              value: "fj",
              flag: "fj",
              phoneCode: 679
            },
            {
              name: "Finland",
              key: "fi",
              text: "Finland",
              value: "fi",
              flag: "fi",
              phoneCode: 358
            },
            {
              name: "France",
              key: "fr",
              text: "France",
              value: "fr",
              flag: "fr",
              phoneCode: 33
            },
            {
              name: "French Guiana",
              key: "gf",
              text: "French Guiana",
              value: "gf",
              flag: "gf",
              phoneCode: 594
            },
            {
              name: "French Polynesia",
              key: "pf",
              text: "French Polynesia",
              value: "pf",
              flag: "pf",
              phoneCode: 689
            },
            {
              name: "French Southern Territories",
              key: "tf",
              text: "French Southern Territories",
              value: "tf",
              flag: "tf",
              phoneCode: 262
            },
            {
              name: "Gabon",
              key: "ga",
              text: "Gabon",
              value: "ga",
              flag: "ga",
              phoneCode: 241
            },
            {
              name: "Gambia",
              key: "gm",
              text: "Gambia",
              value: "gm",
              flag: "gm",
              phoneCode: 220
            },
            {
              name: "Georgia",
              key: "ge",
              text: "Georgia",
              value: "ge",
              flag: "ge",
              phoneCode: 995
            },
            {
              name: "Germany",
              key: "de",
              text: "Germany",
              value: "de",
              flag: "de",
              phoneCode: 49
            },
            {
              name: "Ghana",
              key: "gh",
              text: "Ghana",
              value: "gh",
              flag: "gh",
              phoneCode: 233
            },
            {
              name: "Gibraltar",
              key: "gi",
              text: "Gibraltar",
              value: "gi",
              flag: "gi",
              phoneCode: 350
            },
            {
              name: "Greece",
              key: "gr",
              text: "Greece",
              value: "gr",
              flag: "gr",
              phoneCode: 30
            },
            {
              name: "Greenland",
              key: "gl",
              text: "Greenland",
              value: "gl",
              flag: "gl",
              phoneCode: 299
            },
            {
              name: "Grenada",
              key: "gd",
              text: "Grenada",
              value: "gd",
              flag: "gd",
              phoneCode: 1473
            },
            {
              name: "Guadeloupe",
              key: "gp",
              text: "Guadeloupe",
              value: "gp",
              flag: "gp",
              phoneCode: 590
            },
            {
              name: "Guam",
              key: "gu",
              text: "Guam",
              value: "gu",
              flag: "gu",
              phoneCode: 1671
            },
            {
              name: "Guatemala",
              key: "gt",
              text: "Guatemala",
              value: "gt",
              flag: "gt",
              phoneCode: 502
            },
            {
              name: "Guernsey",
              key: "gg",
              text: "Guernsey",
              value: "gg",
              flag: "gg",
              phoneCode: 44
            },
            {
              name: "Guinea",
              key: "gn",
              text: "Guinea",
              value: "gn",
              flag: "gn",
              phoneCode: 224
            },
            {
              name: "Guinea-Bissau",
              key: "gw",
              text: "Guinea-Bissau",
              value: "gw",
              flag: "gw",
              phoneCode: 245
            },
            {
              name: "Guyana",
              key: "gy",
              text: "Guyana",
              value: "gy",
              flag: "gy",
              phoneCode: 592
            },
            {
              name: "Haiti",
              key: "ht",
              text: "Haiti",
              value: "ht",
              flag: "ht",
              phoneCode: 509
            },
            {
              name: "Heard Island and Mcdonald Islands",
              key: "hm",
              text: "Heard Island and Mcdonald Islands",
              value: "hm",
              flag: "hm",
              phoneCode: 0
            },
            {
              name: "Holy See (Vatican City State)",
              key: "va",
              text: "Holy See (Vatican City State)",
              value: "va",
              flag: "va",
              phoneCode: 39
            },
            {
              name: "Honduras",
              key: "hn",
              text: "Honduras",
              value: "hn",
              flag: "hn",
              phoneCode: 504
            },
            {
              name: "Hong Kong",
              key: "hk",
              text: "Hong Kong",
              value: "hk",
              flag: "hk",
              phoneCode: 852
            },
            {
              name: "Hungary",
              key: "hu",
              text: "Hungary",
              value: "hu",
              flag: "hu",
              phoneCode: 36
            },
            {
              name: "Iceland",
              key: "is",
              text: "Iceland",
              value: "is",
              flag: "is",
              phoneCode: 354
            },
            {
              name: "India",
              key: "in",
              text: "India",
              value: "in",
              flag: "in",
              phoneCode: 91
            },
            {
              name: "Indonesia",
              key: "id",
              text: "Indonesia",
              value: "id",
              flag: "id",
              phoneCode: 62
            },
            {
              name: "Iran, Islamic Republic of",
              key: "ir",
              text: "Iran, Islamic Republic of",
              value: "ir",
              flag: "ir",
              phoneCode: 98
            },
            {
              name: "Iraq",
              key: "iq",
              text: "Iraq",
              value: "iq",
              flag: "iq",
              phoneCode: 964
            },
            {
              name: "Ireland",
              key: "ie",
              text: "Ireland",
              value: "ie",
              flag: "ie",
              phoneCode: 353
            },
            {
              name: "Isle of Man",
              key: "im",
              text: "Isle of Man",
              value: "im",
              flag: "im",
              phoneCode: 44
            },
            {
              name: "Israel",
              key: "il",
              text: "Israel",
              value: "il",
              flag: "il",
              phoneCode: 972
            },
            {
              name: "Italy",
              key: "it",
              text: "Italy",
              value: "it",
              flag: "it",
              phoneCode: 39
            },
            {
              name: "Jamaica",
              key: "jm",
              text: "Jamaica",
              value: "jm",
              flag: "jm",
              phoneCode: 1876
            },
            {
              name: "Japan",
              key: "jp",
              text: "Japan",
              value: "jp",
              flag: "jp",
              phoneCode: 81
            },
            {
              name: "Jersey",
              key: "je",
              text: "Jersey",
              value: "je",
              flag: "je",
              phoneCode: 44
            },
            {
              name: "Jordan",
              key: "jo",
              text: "Jordan",
              value: "jo",
              flag: "jo",
              phoneCode: 962
            },
            {
              name: "Kazakhstan",
              key: "kz",
              text: "Kazakhstan",
              value: "kz",
              flag: "kz",
              phoneCode: 7
            },
            {
              name: "Kenya",
              key: "ke",
              text: "Kenya",
              value: "ke",
              flag: "ke",
              phoneCode: 254
            },
            {
              name: "Kiribati",
              key: "ki",
              text: "Kiribati",
              value: "ki",
              flag: "ki",
              phoneCode: 686
            },
            {
              name: "Korea, Democratic People's Republic of",
              key: "kp",
              text: "Korea, Democratic People's Republic of",
              value: "kp",
              flag: "kp",
              phoneCode: 850
            },
            {
              name: "Korea, Republic of",
              key: "kr",
              text: "Korea, Republic of",
              value: "kr",
              flag: "kr",
              phoneCode: 82
            },
            {
              name: "Kosovo",
              key: "xk",
              text: "Kosovo",
              value: "xk",
              flag: "xk",
              phoneCode: 381
            },
            {
              name: "Kuwait",
              key: "kw",
              text: "Kuwait",
              value: "kw",
              flag: "kw",
              phoneCode: 965
            },
            {
              name: "Kyrgyzstan",
              key: "kg",
              text: "Kyrgyzstan",
              value: "kg",
              flag: "kg",
              phoneCode: 996
            },
            {
              name: "Lao People's Democratic Republic",
              key: "la",
              text: "Lao People's Democratic Republic",
              value: "la",
              flag: "la",
              phoneCode: 856
            },
            {
              name: "Latvia",
              key: "lv",
              text: "Latvia",
              value: "lv",
              flag: "lv",
              phoneCode: 371
            },
            {
              name: "Lebanon",
              key: "lb",
              text: "Lebanon",
              value: "lb",
              flag: "lb",
              phoneCode: 961
            },
            {
              name: "Lesotho",
              key: "ls",
              text: "Lesotho",
              value: "ls",
              flag: "ls",
              phoneCode: 266
            },
            {
              name: "Liberia",
              key: "lr",
              text: "Liberia",
              value: "lr",
              flag: "lr",
              phoneCode: 231
            },
            {
              name: "Libyan Arab Jamahiriya",
              key: "ly",
              text: "Libyan Arab Jamahiriya",
              value: "ly",
              flag: "ly",
              phoneCode: 218
            },
            {
              name: "Liechtenstein",
              key: "li",
              text: "Liechtenstein",
              value: "li",
              flag: "li",
              phoneCode: 423
            },
            {
              name: "Lithuania",
              key: "lt",
              text: "Lithuania",
              value: "lt",
              flag: "lt",
              phoneCode: 370
            },
            {
              name: "Luxembourg",
              key: "lu",
              text: "Luxembourg",
              value: "lu",
              flag: "lu",
              phoneCode: 352
            },
            {
              name: "Macao",
              key: "mo",
              text: "Macao",
              value: "mo",
              flag: "mo",
              phoneCode: 853
            },
            {
              name: "Macedonia, the Former Yugoslav Republic of",
              key: "mk",
              text: "Macedonia, the Former Yugoslav Republic of",
              value: "mk",
              flag: "mk",
              phoneCode: 389
            },
            {
              name: "Madagascar",
              key: "mg",
              text: "Madagascar",
              value: "mg",
              flag: "mg",
              phoneCode: 261
            },
            {
              name: "Malawi",
              key: "mw",
              text: "Malawi",
              value: "mw",
              flag: "mw",
              phoneCode: 265
            },
            {
              name: "Malaysia",
              key: "my",
              text: "Malaysia",
              value: "my",
              flag: "my",
              phoneCode: 60
            },
            {
              name: "Maldives",
              key: "mv",
              text: "Maldives",
              value: "mv",
              flag: "mv",
              phoneCode: 960
            },
            {
              name: "Mali",
              key: "ml",
              text: "Mali",
              value: "ml",
              flag: "ml",
              phoneCode: 223
            },
            {
              name: "Malta",
              key: "mt",
              text: "Malta",
              value: "mt",
              flag: "mt",
              phoneCode: 356
            },
            {
              name: "Marshall Islands",
              key: "mh",
              text: "Marshall Islands",
              value: "mh",
              flag: "mh",
              phoneCode: 692
            },
            {
              name: "Martinique",
              key: "mq",
              text: "Martinique",
              value: "mq",
              flag: "mq",
              phoneCode: 596
            },
            {
              name: "Mauritania",
              key: "mr",
              text: "Mauritania",
              value: "mr",
              flag: "mr",
              phoneCode: 222
            },
            {
              name: "Mauritius",
              key: "mu",
              text: "Mauritius",
              value: "mu",
              flag: "mu",
              phoneCode: 230
            },
            {
              name: "Mayotte",
              key: "yt",
              text: "Mayotte",
              value: "yt",
              flag: "yt",
              phoneCode: 269
            },
            {
              name: "Mexico",
              key: "mx",
              text: "Mexico",
              value: "mx",
              flag: "mx",
              phoneCode: 52
            },
            {
              name: "Micronesia, Federated States of",
              key: "fm",
              text: "Micronesia, Federated States of",
              value: "fm",
              flag: "fm",
              phoneCode: 691
            },
            {
              name: "Moldova, Republic of",
              key: "md",
              text: "Moldova, Republic of",
              value: "md",
              flag: "md",
              phoneCode: 373
            },
            {
              name: "Monaco",
              key: "mc",
              text: "Monaco",
              value: "mc",
              flag: "mc",
              phoneCode: 377
            },
            {
              name: "Mongolia",
              key: "mn",
              text: "Mongolia",
              value: "mn",
              flag: "mn",
              phoneCode: 976
            },
            {
              name: "Montenegro",
              key: "me",
              text: "Montenegro",
              value: "me",
              flag: "me",
              phoneCode: 382
            },
            {
              name: "Montserrat",
              key: "ms",
              text: "Montserrat",
              value: "ms",
              flag: "ms",
              phoneCode: 1664
            },
            {
              name: "Morocco",
              key: "ma",
              text: "Morocco",
              value: "ma",
              flag: "ma",
              phoneCode: 212
            },
            {
              name: "Mozambique",
              key: "mz",
              text: "Mozambique",
              value: "mz",
              flag: "mz",
              phoneCode: 258
            },
            {
              name: "Myanmar",
              key: "mm",
              text: "Myanmar",
              value: "mm",
              flag: "mm",
              phoneCode: 95
            },
            {
              name: "Namibia",
              key: "na",
              text: "Namibia",
              value: "na",
              flag: "na",
              phoneCode: 264
            },
            {
              name: "Nauru",
              key: "nr",
              text: "Nauru",
              value: "nr",
              flag: "nr",
              phoneCode: 674
            },
            {
              name: "Nepal",
              key: "np",
              text: "Nepal",
              value: "np",
              flag: "np",
              phoneCode: 977
            },
            {
              name: "Netherlands",
              key: "nl",
              text: "Netherlands",
              value: "nl",
              flag: "nl",
              phoneCode: 31
            },
            {
              name: "Netherlands Antilles",
              key: "an",
              text: "Netherlands Antilles",
              value: "an",
              flag: "an",
              phoneCode: 599
            },
            {
              name: "New Caledonia",
              key: "nc",
              text: "New Caledonia",
              value: "nc",
              flag: "nc",
              phoneCode: 687
            },
            {
              name: "New Zealand",
              key: "nz",
              text: "New Zealand",
              value: "nz",
              flag: "nz",
              phoneCode: 64
            },
            {
              name: "Nicaragua",
              key: "ni",
              text: "Nicaragua",
              value: "ni",
              flag: "ni",
              phoneCode: 505
            },
            {
              name: "Niger",
              key: "ne",
              text: "Niger",
              value: "ne",
              flag: "ne",
              phoneCode: 227
            },
            {
              name: "Nigeria",
              key: "ng",
              text: "Nigeria",
              value: "ng",
              flag: "ng",
              phoneCode: 234
            },
            {
              name: "Niue",
              key: "nu",
              text: "Niue",
              value: "nu",
              flag: "nu",
              phoneCode: 683
            },
            {
              name: "Norfolk Island",
              key: "nf",
              text: "Norfolk Island",
              value: "nf",
              flag: "nf",
              phoneCode: 672
            },
            {
              name: "Northern Mariana Islands",
              key: "mp",
              text: "Northern Mariana Islands",
              value: "mp",
              flag: "mp",
              phoneCode: 1670
            },
            {
              name: "Norway",
              key: "no",
              text: "Norway",
              value: "no",
              flag: "no",
              phoneCode: 47
            },
            {
              name: "Oman",
              key: "om",
              text: "Oman",
              value: "om",
              flag: "om",
              phoneCode: 968
            },
            {
              name: "Pakistan",
              key: "pk",
              text: "Pakistan",
              value: "pk",
              flag: "pk",
              phoneCode: 92
            },
            {
              name: "Palau",
              key: "pw",
              text: "Palau",
              value: "pw",
              flag: "pw",
              phoneCode: 680
            },
            {
              name: "Palestinian Territory, Occupied",
              key: "ps",
              text: "Palestinian Territory, Occupied",
              value: "ps",
              flag: "ps",
              phoneCode: 970
            },
            {
              name: "Panama",
              key: "pa",
              text: "Panama",
              value: "pa",
              flag: "pa",
              phoneCode: 507
            },
            {
              name: "Papua New Guinea",
              key: "pg",
              text: "Papua New Guinea",
              value: "pg",
              flag: "pg",
              phoneCode: 675
            },
            {
              name: "Paraguay",
              key: "py",
              text: "Paraguay",
              value: "py",
              flag: "py",
              phoneCode: 595
            },
            {
              name: "Peru",
              key: "pe",
              text: "Peru",
              value: "pe",
              flag: "pe",
              phoneCode: 51
            },
            {
              name: "Philippines",
              key: "ph",
              text: "Philippines",
              value: "ph",
              flag: "ph",
              phoneCode: 63
            },
            {
              name: "Pitcairn",
              key: "pn",
              text: "Pitcairn",
              value: "pn",
              flag: "pn",
              phoneCode: 64
            },
            {
              name: "Poland",
              key: "pl",
              text: "Poland",
              value: "pl",
              flag: "pl",
              phoneCode: 48
            },
            {
              name: "Portugal",
              key: "pt",
              text: "Portugal",
              value: "pt",
              flag: "pt",
              phoneCode: 351
            },
            {
              name: "Puerto Rico",
              key: "pr",
              text: "Puerto Rico",
              value: "pr",
              flag: "pr",
              phoneCode: 1787
            },
            {
              name: "Qatar",
              key: "qa",
              text: "Qatar",
              value: "qa",
              flag: "qa",
              phoneCode: 974
            },
            {
              name: "Reunion",
              key: "re",
              text: "Reunion",
              value: "re",
              flag: "re",
              phoneCode: 262
            },
            {
              name: "Romania",
              key: "ro",
              text: "Romania",
              value: "ro",
              flag: "ro",
              phoneCode: 40
            },
            {
              name: "Russian Federation",
              key: "ru",
              text: "Russian Federation",
              value: "ru",
              flag: "ru",
              phoneCode: 70
            },
            {
              name: "Rwanda",
              key: "rw",
              text: "Rwanda",
              value: "rw",
              flag: "rw",
              phoneCode: 250
            },
            {
              name: "Saint Barthelemy",
              key: "bl",
              text: "Saint Barthelemy",
              value: "bl",
              flag: "bl",
              phoneCode: 590
            },
            {
              name: "Saint Helena",
              key: "sh",
              text: "Saint Helena",
              value: "sh",
              flag: "sh",
              phoneCode: 290
            },
            {
              name: "Saint Kitts and Nevis",
              key: "kn",
              text: "Saint Kitts and Nevis",
              value: "kn",
              flag: "kn",
              phoneCode: 1869
            },
            {
              name: "Saint Lucia",
              key: "lc",
              text: "Saint Lucia",
              value: "lc",
              flag: "lc",
              phoneCode: 1758
            },
            {
              name: "Saint Martin",
              key: "mf",
              text: "Saint Martin",
              value: "mf",
              flag: "mf",
              phoneCode: 590
            },
            {
              name: "Saint Pierre and Miquelon",
              key: "pm",
              text: "Saint Pierre and Miquelon",
              value: "pm",
              flag: "pm",
              phoneCode: 508
            },
            {
              name: "Saint Vincent and the Grenadines",
              key: "vc",
              text: "Saint Vincent and the Grenadines",
              value: "vc",
              flag: "vc",
              phoneCode: 1784
            },
            {
              name: "Samoa",
              key: "ws",
              text: "Samoa",
              value: "ws",
              flag: "ws",
              phoneCode: 684
            },
            {
              name: "San Marino",
              key: "sm",
              text: "San Marino",
              value: "sm",
              flag: "sm",
              phoneCode: 378
            },
            {
              name: "Sao Tome and Principe",
              key: "st",
              text: "Sao Tome and Principe",
              value: "st",
              flag: "st",
              phoneCode: 239
            },
            {
              name: "Saudi Arabia",
              key: "sa",
              text: "Saudi Arabia",
              value: "sa",
              flag: "sa",
              phoneCode: 966
            },
            {
              name: "Senegal",
              key: "sn",
              text: "Senegal",
              value: "sn",
              flag: "sn",
              phoneCode: 221
            },
            {
              name: "Serbia",
              key: "rs",
              text: "Serbia",
              value: "rs",
              flag: "rs",
              phoneCode: 381
            },
            {
              name: "Serbia and Montenegro",
              key: "cs",
              text: "Serbia and Montenegro",
              value: "cs",
              flag: "cs",
              phoneCode: 381
            },
            {
              name: "Seychelles",
              key: "sc",
              text: "Seychelles",
              value: "sc",
              flag: "sc",
              phoneCode: 248
            },
            {
              name: "Sierra Leone",
              key: "sl",
              text: "Sierra Leone",
              value: "sl",
              flag: "sl",
              phoneCode: 232
            },
            {
              name: "Singapore",
              key: "sg",
              text: "Singapore",
              value: "sg",
              flag: "sg",
              phoneCode: 65
            },
            {
              name: "Sint Maarten",
              key: "sx",
              text: "Sint Maarten",
              value: "sx",
              flag: "sx",
              phoneCode: 1
            },
            {
              name: "Slovakia",
              key: "sk",
              text: "Slovakia",
              value: "sk",
              flag: "sk",
              phoneCode: 421
            },
            {
              name: "Slovenia",
              key: "si",
              text: "Slovenia",
              value: "si",
              flag: "si",
              phoneCode: 386
            },
            {
              name: "Solomon Islands",
              key: "sb",
              text: "Solomon Islands",
              value: "sb",
              flag: "sb",
              phoneCode: 677
            },
            {
              name: "Somalia",
              key: "so",
              text: "Somalia",
              value: "so",
              flag: "so",
              phoneCode: 252
            },
            {
              name: "South Africa",
              key: "za",
              text: "South Africa",
              value: "za",
              flag: "za",
              phoneCode: 27
            },
            {
              name: "South Georgia and the South Sandwich Islands",
              key: "gs",
              text: "South Georgia and the South Sandwich Islands",
              value: "gs",
              flag: "gs",
              phoneCode: 500
            },
            {
              name: "South Sudan",
              key: "ss",
              text: "South Sudan",
              value: "ss",
              flag: "ss",
              phoneCode: 211
            },
            {
              name: "Spain",
              key: "es",
              text: "Spain",
              value: "es",
              flag: "es",
              phoneCode: 34
            },
            {
              name: "Sri Lanka",
              key: "lk",
              text: "Sri Lanka",
              value: "lk",
              flag: "lk",
              phoneCode: 94
            },
            {
              name: "Sudan",
              key: "sd",
              text: "Sudan",
              value: "sd",
              flag: "sd",
              phoneCode: 249
            },
            {
              name: "Suriname",
              key: "sr",
              text: "Suriname",
              value: "sr",
              flag: "sr",
              phoneCode: 597
            },
            {
              name: "Svalbard and Jan Mayen",
              key: "sj",
              text: "Svalbard and Jan Mayen",
              value: "sj",
              flag: "sj",
              phoneCode: 47
            },
            {
              name: "Swaziland",
              key: "sz",
              text: "Swaziland",
              value: "sz",
              flag: "sz",
              phoneCode: 268
            },
            {
              name: "Sweden",
              key: "se",
              text: "Sweden",
              value: "se",
              flag: "se",
              phoneCode: 46
            },
            {
              name: "Switzerland",
              key: "ch",
              text: "Switzerland",
              value: "ch",
              flag: "ch",
              phoneCode: 41
            },
            {
              name: "Syrian Arab Republic",
              key: "sy",
              text: "Syrian Arab Republic",
              value: "sy",
              flag: "sy",
              phoneCode: 963
            },
            {
              name: "Taiwan, Province of China",
              key: "tw",
              text: "Taiwan, Province of China",
              value: "tw",
              flag: "tw",
              phoneCode: 886
            },
            {
              name: "Tajikistan",
              key: "tj",
              text: "Tajikistan",
              value: "tj",
              flag: "tj",
              phoneCode: 992
            },
            {
              name: "Tanzania, United Republic of",
              key: "tz",
              text: "Tanzania, United Republic of",
              value: "tz",
              flag: "tz",
              phoneCode: 255
            },
            {
              name: "Thailand",
              key: "th",
              text: "Thailand",
              value: "th",
              flag: "th",
              phoneCode: 66
            },
            {
              name: "Timor-Leste",
              key: "tl",
              text: "Timor-Leste",
              value: "tl",
              flag: "tl",
              phoneCode: 670
            },
            {
              name: "Togo",
              key: "tg",
              text: "Togo",
              value: "tg",
              flag: "tg",
              phoneCode: 228
            },
            {
              name: "Tokelau",
              key: "tk",
              text: "Tokelau",
              value: "tk",
              flag: "tk",
              phoneCode: 690
            },
            {
              name: "Tonga",
              key: "to",
              text: "Tonga",
              value: "to",
              flag: "to",
              phoneCode: 676
            },
            {
              name: "Trinidad and Tobago",
              key: "tt",
              text: "Trinidad and Tobago",
              value: "tt",
              flag: "tt",
              phoneCode: 1868
            },
            {
              name: "Tunisia",
              key: "tn",
              text: "Tunisia",
              value: "tn",
              flag: "tn",
              phoneCode: 216
            },
            {
              name: "Turkey",
              key: "tr",
              text: "Turkey",
              value: "tr",
              flag: "tr",
              phoneCode: 90
            },
            {
              name: "Turkmenistan",
              key: "tm",
              text: "Turkmenistan",
              value: "tm",
              flag: "tm",
              phoneCode: 7370
            },
            {
              name: "Turks and Caicos Islands",
              key: "tc",
              text: "Turks and Caicos Islands",
              value: "tc",
              flag: "tc",
              phoneCode: 1649
            },
            {
              name: "Tuvalu",
              key: "tv",
              text: "Tuvalu",
              value: "tv",
              flag: "tv",
              phoneCode: 688
            },
            {
              name: "Uganda",
              key: "ug",
              text: "Uganda",
              value: "ug",
              flag: "ug",
              phoneCode: 256
            },
            {
              name: "Ukraine",
              key: "ua",
              text: "Ukraine",
              value: "ua",
              flag: "ua",
              phoneCode: 380
            },
            {
              name: "United Arab Emirates",
              key: "ae",
              text: "United Arab Emirates",
              value: "ae",
              flag: "ae",
              phoneCode: 971
            },
            {
              name: "United Kingdom",
              key: "gb",
              text: "United Kingdom",
              value: "gb",
              flag: "gb",
              phoneCode: 44
            },
            {
              name: "United States",
              key: "us",
              text: "United States",
              value: "us",
              flag: "us",
              phoneCode: 1
            },
            {
              name: "United States Minor Outlying Islands",
              key: "um",
              text: "United States Minor Outlying Islands",
              value: "um",
              flag: "um",
              phoneCode: 1
            },
            {
              name: "Uruguay",
              key: "uy",
              text: "Uruguay",
              value: "uy",
              flag: "uy",
              phoneCode: 598
            },
            {
              name: "Uzbekistan",
              key: "uz",
              text: "Uzbekistan",
              value: "uz",
              flag: "uz",
              phoneCode: 998
            },
            {
              name: "Vanuatu",
              key: "vu",
              text: "Vanuatu",
              value: "vu",
              flag: "vu",
              phoneCode: 678
            },
            {
              name: "Venezuela",
              key: "ve",
              text: "Venezuela",
              value: "ve",
              flag: "ve",
              phoneCode: 58
            },
            {
              name: "Viet Nam",
              key: "vn",
              text: "Viet Nam",
              value: "vn",
              flag: "vn",
              phoneCode: 84
            },
            {
              name: "Virgin Islands, British",
              key: "vg",
              text: "Virgin Islands, British",
              value: "vg",
              flag: "vg",
              phoneCode: 1284
            },
            {
              name: "Virgin Islands, U.s.",
              key: "vi",
              text: "Virgin Islands, U.s.",
              value: "vi",
              flag: "vi",
              phoneCode: 1340
            },
            {
              name: "Wallis and Futuna",
              key: "wf",
              text: "Wallis and Futuna",
              value: "wf",
              flag: "wf",
              phoneCode: 681
            },
            {
              name: "Western Sahara",
              key: "eh",
              text: "Western Sahara",
              value: "eh",
              flag: "eh",
              phoneCode: 212
            },
            {
              name: "Yemen",
              key: "ye",
              text: "Yemen",
              value: "ye",
              flag: "ye",
              phoneCode: 967
            },
            {
              name: "Zambia",
              key: "zm",
              text: "Zambia",
              value: "zm",
              flag: "zm",
              phoneCode: 260
            },
            {
              name: "Zimbabwe",
              key: "zw",
              text: "Zimbabwe",
              value: "zw",
              flag: "zw",
              phoneCode: 263
            }
          ]
        this.setState({
            ...this.state,
            modalOpen: true,
            parentState: this.props.parentState,
            countryOptions: countries
        })
    }

    handleOpen = () => this.setState({ modalOpen: true });

    handleClose = () => {
        this.setState({ modalOpen: false })
        this.props.parentCallback(false);
        window.location.reload();
    }

    handleInputChange = (e) => {
        this.setState({
          ...this.state,
          [e.target.name]: e.target.value
        })
    }

    configureCaptcha = () => {
        console.log('inside configureCaptcha')
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
          'size': 'invisible',
          'callback': (response) => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            this.handleOTPRequest();
            console.log("Recaptca verified")
          },
          defaultCountry: "US"
        });
      }

    handleOTPRequest = async(e) => {
        //e.preventDefault()
        console.log('inside handleOTPRequest')
        if(this.state.phoneNumber && this.state.phoneNumber.length===10) {  
            this.configureCaptcha();
            const phoneNumber = this.state.label + this.state.phoneNumber;
            console.log(phoneNumber);
            const appVerifier = window.recaptchaVerifier;
            firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
                .then((confirmationResult) => {
                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).
                window.confirmationResult = confirmationResult;
                console.log("OTP has been sent");
                this.setState({
                    ...this.state,
                    verificationCodeSent: true,
                    //put state's phone no into parent state because thats going to be sent to backend
                    message: 'A verification code has been sent to your number. Please enter it below and verify'
                })
                // ...
                }).catch((error) => {
                // Error; SMS not sent
                    console.log("SMS not sent", error);
                    // this.setState({
                    //     ...this.state,
                    //     message: 'Phone number invalid or does not exist'
                    // })
                });
        } else {
            this.setState({
                ...this.state,
                message: 'Phone number invalid (incorrect format) or does not exist'
            })
        }        
    }

    handleVerification = async(e) => {
        console.log('inside handleVerification');
        //e.preventDefault();
        if(this.state.phoneNumber && this.state.otpEntered.length===6) {
            const code = this.state.otpEntered;
            console.log(code)
            window.confirmationResult.confirm(code).then((result) => {
                // User signed in successfully.
                const user = result.user;
                console.log(JSON.stringify(user))
                let parentState = this.state.parentState;
                parentState.signInUserPhone = `${this.state.label}${this.state.phoneNumber}`;
                console.log('parentState after phone no verification', parentState)
                this.setState({
                    ...this.state,
                    verified: true,
                    parentState,
                    message: 'Phone number verified and request forwarded. You can close this window'
                }, async() => {
                    let authComponent = this.props.user.auth;
                    let stateComponent = this.state.parentState;
                    let requestComponent = {stateComponent, authComponent}
                    console.log('requestComponent', requestComponent)
                    await this.props.createRequest(requestComponent);
                })
            }).catch((error) => {
                console.log('error', error)
                this.setState({
                    ...this.state,
                    verified: true,
                    message: 'OTP invalid. Retry'
                })
            });
        } else {
            this.setState({
                ...this.state,
                message: 'OTP code cannot be left empty or invalid'
            })
        }
    }

    handleChange = (e, data) => {
        console.log('data inside handleChange', data);
        let label;
        for(let country of this.state.countryOptions) {
            if(country.value === data.value) {
                label= `+${country.phoneCode}`;
                break;
            }
        }
        this.setState({
          ...this.state,
          [data.name]: data.value,
          label
        })
      }

    render() {
        // console.log('props in modal', this.props)
        console.log('state in otp verification modal', this.state);
        return (
            <div>
            <Modal
                open={this.state.modalOpen}
                onClose={this.handleClose}
                closeIcon
            >
                {!this.state.verified && (
                    <>
                        <Modal.Header>2 Factor Authentication</Modal.Header>
                        <Modal.Content>
                            <p>
                                Please enter your phone number for verification
                            </p>
                            <div id="sign-in-button"></div>
                        <Form>
                            <Form.Field inline>
                                <label>Country</label>
                                <Dropdown
                                    style={{width: '80%'}}
                                    name="country"
                                    placeholder='Select Country'
                                    fluid
                                    search
                                    selection
                                    options={this.state.countryOptions}
                                    onChange={this.handleChange}
                                />
                            </Form.Field>
                            {this.state.label && (
                              <Form.Field>
                                <label>Phone number</label>
                                {/* <br /> */}
                                <Input name="phoneNumber" label={this.state.label} placeholder='enter phone number' style={{width: '40%'}} 
                                  onChange={this.handleInputChange}/>
                                <br />
                                <Button primary style={{ marginTop: '5px'}} onClick={this.handleOTPRequest}>Request OTP</Button>
                              </Form.Field>
                            )}
                            
                            {this.state.verificationCodeSent && (
                                <Form.Field >
                                    <label>OTP</label>
                                    <Input name="otpEntered" placeholder='enter OTP sent to your number' style={{width: '40%'}} 
                                    onChange={this.handleInputChange}/>
                                    <Button style={{ marginLeft: '10px'}} color='green' onClick={this.handleVerification}>
                                        Verify
                                    </Button>
                                </Form.Field>
                            )}  
                        </Form>
                        {this.state.message && (
                            <Message attached='bottom' style={{ marginTop: '10px', textAlign: 'center'}}>
                                {this.state.message}
                            </Message>
                        )}
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={this.handleClose}>
                            Cancel
                        </Button>
                    </Modal.Actions>
                    </>
                )}

                {this.state.verified && (
                    <>
                        <Modal.Content>
                        <h2 style={{ textAlign: 'center'}}>
                            Request Sent! <Icon color="green" name="check circle" />
                        </h2>
                        <div style={{ textAlign: 'center'}}>
                            You will be recieving whatsapp notifications to the number you signed up with
                        </div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={this.handleClose}>
                                Close
                            </Button>
                        </Modal.Actions>
                    </>
                )}
            </Modal>
        </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {user: state}
}

export default connect(mapStateToProps, {fetchUser, createRequest})(OTPVerificationModal);