import LeafletMap from "./LeafletMap";
import { Grid, Image, Icon, Form, Select, Button, Segment, 
  TextArea, Dropdown, Header, Modal, Popup, Input, Label, Divider } from 'semantic-ui-react'
import { Component } from 'react'
import moment from 'moment'
import { connect } from "react-redux";
import axios from 'axios'
import RequestConfirmationModal from './modals/RequestConfirmationModal'
import RequestsTablePage from "./RequestsTablePage";
import NotSignedInModal from "./modals/NotSignedInModal";
import OTPVerificationModal from "./modals/OTPVerificationModal";
import { FilePond } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import React from "react";
import { bounce } from 'react-animations';
import Radium, {StyleRoot} from 'radium';
// import Draggable from 'react-draggable';

//TO-DO make search page for every user, to view his history of trips
//TO-DO onchange for date, ask for phone number and do OTP verification if new user
//after submit, do DB call to check if user exists, if not prompt modal if confirm to put request. After clicking confirm, ask for phone number

//TO-DO calendar insertion of events, set leaflet routing waypoints from dropoff locations

//Message in the end, 'Your request was submitted, you will receieve an email showing availability and confirmation
//TO_DO ask in detail in text area about what user is going to do at every destination and what hes going ot pick up
//users should be able to cancel the ride anytime. But not before 10 minutes.
//TO-DO if signed in user is admin, dont render request form and request table, instead a menu for /settings and /decisions
//If current day/time exceeds the req date, change the req date to ''cancel
class PageGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pickUp: '',
      dropOff: [],
      capacity: 1,
      requestDate: null,
      hitchRequired: false,
      personalizedInstructions: '',
      openConfirmationModal: false,
      signedInEmail: null,
      signInUserPhone: null,
      message: null,
      openNotSignedInModal: false,
      openOTPVerificationModal: false,
      error: false,
      pickUpOptions: [],
      dropOffOptions: [],
      adminEmails: [],
      selectedAddressLatLng: [],
      recentConfirmedRequest: "",
      openFilePond: false,
      view: 'calendar',
      files: [],
      isSignedInEmailAnAdmin: false,
      toggledNewsModal: false,
      toggledGotchasModal: false
    }
  }

  //TO-DO create OTP modal which gets user phone number and updates it to the user document after verification
  componentWillMount = async() => {
    await this.fetchSettings();
    await this.getCurrentPosition();

    function setCookie(c_name,value,exdays){var exdate=new Date();exdate.setDate(exdate.getDate() + exdays);var c_value=escape(value) + ((exdays==null) ? "" : "; expires="+exdate.toUTCString());document.cookie=c_name + "=" + c_value;}

    function getCookie(c_name){var c_value = document.cookie;var c_start = c_value.indexOf(" " + c_name + "=");if (c_start == -1){c_start = c_value.indexOf(c_name + "=");}if (c_start == -1){c_value = null;}else{c_start = c_value.indexOf("=", c_start) + 1;var c_end = c_value.indexOf(";", c_start);if (c_end == -1){c_end = c_value.length;}c_value = unescape(c_value.substring(c_start,c_end));}return c_value;}

    let toggleNewsValue = checkSession();
    if(toggleNewsValue) {
      this.setState({
        ...this.state,
        toggledNewsModal: false
      })
    } else {
      this.setState({
        ...this.state,
        toggledNewsModal: true
      })
    }

    function checkSession() {
      var c = getCookie("visited");
      console.log('cookie value in checkSession', c)
      setCookie("visited", "yes", 7); // expire in 7 days; or use null to never expire
      if (c === "yes") {
        return true;
      } else {
        return false;        
      }
    }
  }

  getCurrentPosition = () => {
    let selectedAddressLatLng = this.state.selectedAddressLatLng;

    const location = window.navigator && window.navigator.geolocation
    if (location) {
      location.getCurrentPosition((position) => {
        let positionObject = {};
        positionObject.lat = position.coords.latitude;
        positionObject.lng = position.coords.longitude;
        positionObject.location = 'Current';

        selectedAddressLatLng.unshift(positionObject);
        this.setState({
          ...this.state,
          selectedAddressLatLng: selectedAddressLatLng
        })
      }
    )} else {
      console.log('geolocation permission not given')
    }
  }

  fetchSettings = async() => {
    const response = await axios.get('/settings_route', {});
    this.setState({
      ...this.state,
      pickUpOptions: response.data.response.pick_up_locations,
      dropOffOptions: response.data.response.drop_off_locations,
      adminEmails: response.data.response.admin_emails,
      toggledNewsModal: true
    })
  }

  fetchLeafletRequest = async (email) => {
    //console.log('email in fetch leaflet req', this.state.signedInEmail)
    const response = await axios.get(`/leaflet_request/${email}`, {});
    console.log('response in leaflet fetch', response)
    if(response.data.response) {
      let selectedAddressLatLng = this.state.selectedAddressLatLng;
      //let selectedAddressLatLng = [];
      selectedAddressLatLng = selectedAddressLatLng.concat(response.data.response)
      this.setState({
        ...this.state,
        selectedAddressLatLng: selectedAddressLatLng,
        recentConfirmedRequest: response.data.recentConfirmedRequest
      })
    }
  }

  componentDidMount = () => {
    console.log('inside component did mount page grid')
    const currentDate = moment().format('YYYY-MM-DD');
    const currentTime = moment().format('HH:mm')
    let currentDateTime = `${currentDate}T${currentTime}`
    currentDateTime = currentDateTime.split(' ')[0];

    this.setState({
      ...this.state,
      requestDate: currentDateTime
    })
  }

  componentDidUpdate = async() => {
    if(this.state.signedInEmail===null && this.props.user.auth.userEmail) {
      console.log('inside componentDidUpdate')
      this.setState({
        ...this.state,
        signedInEmail: this.props.user.auth.userEmail,
      }, async() => {
        // console.log('inside async of did update')
        // await this.fetchLeafletRequest(this.state.signedInEmail);
        let signedInUserDetails = await this.fetchUserDetails(this.state.signedInEmail);
        if(signedInUserDetails.data.phone) {
          this.setState({
            ...this.state,
            signInUserPhone: signedInUserDetails.data.phone
          })
        }
        if(this.state.adminEmails.includes(this.state.signedInEmail)) {
          this.setState({
            ...this.state,
            isSignedInEmailAnAdmin: true
          })
        }
        if(signedInUserDetails.data.residence) {
          this.setState({
            ...this.state,
            pickUp: signedInUserDetails.data.residence
          })
        }
      })
    }
  }

  fetchUserDetails = async(email) => {
    let signedInUserDetails = await axios.get(`/users/${email}`)
    console.log('signedInUserDetails', signedInUserDetails)
    return signedInUserDetails;
  }

  onSendRequest = async() => {
    // add validation so that values are not empty
    let authComponent = this.props.user.auth;
    if(authComponent.isSignedIn) {
      console.log('user is signed in')
      let signedInEmail = authComponent.userEmail
      //checking if the user exists in the db
      let signedInUserDetails = await this.fetchUserDetails(signedInEmail)
      let fetchedUserEmailId = signedInUserDetails.data.emailId
      if(signedInEmail === fetchedUserEmailId && signedInUserDetails.data.phone!=="") {
        console.log('user exists, asking for request confirmation');
        if(this.state.pickUp && 
          this.state.dropOff.length!==0 &&
          this.state.capacity>0 &&
          this.state.requestDate
          ) {
          this.openConfirmationModal()
        } else {
          this.setState({
            ...this.state,
            error: true
          })
        }
      } else {
        //open modal for OTP verification
        console.log('user doesnt exist, opening OTP verification modal')
        if(this.state.pickUp && 
          this.state.dropOff.length!==0 &&
          this.state.capacity>0 &&
          this.state.requestDate
          ) {
            this.openOTPVerificationModal()
        } else {
          this.setState({
            ...this.state,
            error: true
          })
        }
      }
    } else {
      //sign in to continue message
      this.openNotSignedInModal()
    }
    console.log('after fetchuser in page grid')
  }

  openConfirmationModal = () => {
    this.setState({
      ...this.setState,
      openConfirmationModal: true
    })
  }

  openOTPVerificationModal = () => {
    this.setState({
      ...this.setState,
      openOTPVerificationModal: true
    })
  }

  openNotSignedInModal = () => {
    this.setState({
      ...this.setState,
      openNotSignedInModal: true
    })
  }

  handleRequestConfirmation = (childData) => {
    this.setState({
      ...this.state,
      openConfirmationModal: childData
    })
  }

  handleSignedIn = () => {
    this.setState({
      ...this.state,
      openNotSignedInModal: false
    })
  }

  handlePhoneVerification = () => {
    this.setState({
      ...this.state,
      openOTPVerificationModal: false
    })
  }

  handleChange = (e, data) => {
    console.log('name', data.name)
    if(e.name='dropOff') {
      this.setState({
        ...this.state,
        [data.name]: data.value
      }, () => {
        let dropOffArray = this.state.dropOff;
        //let existingPersonalizedInstructions = this.state.personalizedInstructions;
        //TO-DO try to add new line after every drop off
        // dropOffArray.join(": ")
        //console.log('dropOffArray', dropOffArray)
        this.setState({
          ...this.state,
          //personalizedInstructions: `${dropOffArray}`,
          error: false
        })
      })
    } else {
      this.setState({
        ...this.state,
        [data.name]: data.value,
        error: false
      });
    }
  };

  handleInputChange = (e) => {
    console.log(e.target.name)
    console.log(e.target.value)
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
      error: false
    })
  }

  handleFilePondFiles = (files) => {
    console.log('inside handleFilePondFiles', files[0].file);
    let fileArray = [];
    files.map(file => {
      let fileName = file.file.name;
      let fileString = 'https://storage.googleapis.com/shep_bucket/' + fileName;
      fileArray.push(fileString);
    })
    console.log('fileArray', fileArray)
    this.setState({
      ...this.state,
      files: fileArray
    })
  }

  handlePickUpAddition = (e, { value }) => {
    this.setState((prevState) => ({
      pickUpOptions: [{ text: value, value }, ...prevState.pickUpOptions],
    }))
  }

  handleDropOffAddition = (e, { value }) => {
    this.setState((prevState) => ({
      dropOffOptions: [{ text: value, value }, ...prevState.dropOffOptions],
    }))
  }

  handleNewsModalClose = () => {
    this.setState({
      ...this.state,
      toggledNewsModal: false
      //toggledGotchasModal: true
    })
  }

  handleGotchasClose = () => {
    this.setState({
      ...this.state,
      toggledGotchasModal: false
    })
  }

  renderNewsModal = () => {
    return (
      <Modal
        closeIcon
        onClose={this.handleNewsModalClose}
        open={this.state.toggledNewsModal}
        >
        <Header content='News' />
        <Modal.Content image scrolling>
          <Image size='medium' src='https://storage.googleapis.com/shep_bucket/happy%20holidays.jpg' />
          <Modal.Description>
            <p>
              We have a couple of interesting events planned for over the winter, stay tuned.
            </p>
          </Modal.Description>
          <ul>
            <li>If you are a student arriving to the US for the first time, please follow to the <b>Student Checklist</b> page.</li>
          </ul>
          <div style={{ marginTop: '10px', marginLeft: '30px'}}>
            <Button 
              onClick={() => {window.location.href="/checklist"}} 
              secondary size='small' 
              >
              Arrival Checklist
            </Button>
            {/* <Button 
              onClick={() => {window.location.href="/settings"}}
              color='black'
              size='small' 
              style={{ marginLeft: '15px'}} 
              >
              Settings
            </Button> */}
          </div>
          <Divider />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => this.handleNewsModalClose()} primary>
            Proceed <Icon name='chevron right' />
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }

  renderGotchasModal = () => {
    return (
      <Modal
          closeIcon
          onClose={this.handleGotchasClose}
          open={this.state.toggledGotchasModal}
          size='large'
          >
          <Header content="Gotcha's" />
          <Modal.Content image scrolling>
            <Modal.Description>
              <p>
                If you're not here to schedule a ride, we have other options you can visit,
              </p>
            </Modal.Description>
            {this.state.isSignedInEmailAnAdmin && (<div>
              <ul>
                <li>To Approve or Deny requests, please follow to the <b>Decisions</b> page.</li>
                <li>To Update settings, please follow to the <b>Settings</b> page.</li>
              </ul>
              <div style={{ marginTop: '10px', marginLeft: '30px'}}>
                <Button 
                  onClick={() => {window.location.href="/decisions"}} 
                  primary size='small' 
                  // style={{ display: 'inline'}} 
                  >
                  Decisions
                </Button>
                <Button 
                  onClick={() => {window.location.href="/settings"}}
                  color='black'
                  size='small' 
                  style={{ marginLeft: '10px'}} 
                  >
                  Settings
                </Button>
              </div>
              <Divider />
            </div>)}
            <ul>
              <li>Feel free to check out the <b>Testimonials</b> page.</li>
              <li>And if you're a new student arriving to the US, you might want to give the nod for the <b>Student Checklist</b> page.</li>
            </ul>
            <div style={{ marginLeft: '30px'}}>
              <Button 
                onClick={() => {window.location.href="/testimonials"}} 
                primary size='small'
                >
                Testimonials
              </Button>
              <Button 
                onClick={() => {window.location.href="/checklist"}}
                color='black'
                size='small' 
                style={{ marginLeft: '10px'}} 
                >
                Student Checklist
              </Button>
            </div>
            <Divider />
            <Modal.Description>
              <p>
                Sheppie version update include the below features,
              </p>
            </Modal.Description>
            <ul>
              <li>You can <b>attach image</b> files by clicking on the <b>pin</b> icon beside the <i>personalized instructions</i> label</li>
              <br/>
                Often used examples include <b>furniture</b> images when picking them up from marketplace.<br/>
                <br/>
                Others include <b>flight information</b>.
            </ul>
            <iframe src="https://giphy.com/embed/paXJhx1fdLVdUeGMtP" width="360" height="256" frameBorder="0" class="giphy-embed" allowFullScreen></iframe>
            <br />
            
            <ul>
              <li>You can enter new <b>pick up</b> and <b>drop off</b> locations to the request form by typing the location</li>
            </ul>
            <iframe style={{ marginLeft: '-30px'}} src="https://giphy.com/embed/vgXQ15M669uKVmI6ql" width="400" height="274" frameBorder="0" class="giphy-embed" allowFullScreen></iframe>
            <br />

            <ul>
              <li>You can update your <b>profile information</b> by clicking on the <i>profile</i> icon next to your name on the header</li>
            </ul>
            <iframe style={{ marginLeft: '30px'}} src="https://giphy.com/embed/oPmkIUmo74FO1Moa5g" width="320" height="54" frameBorder="0" class="giphy-embed" allowFullScreen></iframe>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => this.handleGotchasClose()}>
              Close
            </Button>
          </Modal.Actions>
      </Modal>
    )
  }

  handleQuestionClick = () => {
    console.log('inside question button click')
    this.setState({
      ...this.state,
      toggledGotchasModal: true
    })
  }

  render() {
    console.log('props', this.props);
    const currentDate = moment().format('YYYY-MM-DD');
    const currentTime = moment().format('HH:mm')
    const currentDateTime = `${currentDate}T${currentTime}`

    const styles = {
      bounce: {
        animation: 'x 1s',
        animationName: Radium.keyframes(bounce, 'bounce'),
        animationDelay: '10s'
      }
    }

    let iframe = '<iframe src="https://calendar.google.com/calendar/embed?src=leonharper.isi%40gmail.com&ctz=America%2FLos_Angeles" style="border: 0" width="100%" height="310" frameborder="0" scrolling="no"></iframe>'
    //let iframe = '<iframe src="https://calendar.google.com/calendar/embed?src=7hpm3bbd0ta7bj614fdacg37pc%40group.calendar.google.com&ctz=America%2FLos_Angeles" style="border: 0" width="100%" height="310" frameborder="0" scrolling="no"></iframe>'
    let mobileIframe = '<iframe src="https://calendar.google.com/calendar/embed?src=leonharper.isi%40gmail.com&ctz=America%2FLos_Angeles" style="border: 0" width="100%" height="310" frameborder="0" scrolling="no"></iframe>'
    let isMobile = false; //initiate as false
    // device detection
    if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
      || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) { 
      isMobile = true;
    }
    console.log('state in pageGrid', this.state);
    return (
      <Segment>
        {isMobile && (
          <div>
          <Grid>
              <Form style={{ marginTop: '15px', width: '100%'}}>
              <Form.Group widths='equal'>
                <Popup 
                content='Begin typing to add new pick up location' 
                position='top left' size='mini' 
                trigger={
                  <Form.Field width={1} error={this.state.error}>
                    <label>From</label>
                    <Select
                      name = 'pickUp'
                      placeholder='Pick up'
                      value={this.state.pickUp}
                      options={this.state.pickUpOptions} 
                      onChange={this.handleChange}
                      search
                      allowAdditions
                      onAddItem={this.handlePickUpAddition}
                    />
                  </Form.Field>
                } />
                <Popup content='Begin typing to add new drop off location' 
                  size='mini' 
                  trigger={
                  <Form.Field width={4} error={this.state.error}>
                    <label>To</label>
                    <Dropdown 
                      name = 'dropOff'
                      placeholder='Drop-off' 
                      fluid 
                      multiple 
                      selection
                      search
                      options={this.state.dropOffOptions} 
                      onChange={this.handleChange}
                      allowAdditions
                      onAddItem={this.handleDropOffAddition}
                    />
                  </Form.Field>
                }/>
              </Form.Group>
              <Form.Group widths='equal'>
                <Form.Field width={2} error={this.state.error}>
                  <label>Passengers</label>
                  <Input type="number" id="capacity" 
                    name="capacity" min="1" max="6"
                    value={this.state.capacity}
                    onChange={this.handleInputChange}
                    />
                </Form.Field>
                <Form.Field width={5} error={this.state.error}>
                  <label for="requestDate">Approximate timing (Pacific Time)</label>
                    <input type="datetime-local" id="requestDate" min={currentDateTime.split(' ')[0]}
                  name="requestDate" value={this.state.requestDate} onChange={this.handleInputChange}/>
                </Form.Field>
                <div>
                  <Popup content='Trailer hitch for the van' size='mini' trigger={
                    <Form.Field width={1} error={this.state.error} inline style={{ display: 'inline'}}>
                      <label>Trailer required?</label>
                      <br />
                      <div style={{ marginTop: '10px', marginLeft: '10px'}}>
                        <input type="radio" id="true" name="hitchRequired" 
                          value="true" onChange={this.handleInputChange}/>
                          <Label for="hitch">yes</Label>
                          <br/>
                        <input type="radio" id="false" name="hitchRequired" 
                        value="false" onChange={this.handleInputChange} defaultChecked
                        style={{ marginTop: '10px'}}/>
                          <Label for="hitch">no</Label> 
                      </div>
                    </Form.Field>
                  } />
                  <StyleRoot style={{ position: 'absolute', marginLeft: '85%', top: '55%'}}>
                    <div className="test" style={styles.bounce}>
                      <Button 
                      color='black' 
                      circular 
                      icon='question'
                      onClick={() => {
                        this.handleQuestionClick()
                      }}
                    />
                    </div>
                  </StyleRoot>
                </div>
              </Form.Group>
                <Form.Field inline>
                  <label>Personalized instructions</label>
                  <Popup 
                    content='Click to attach files' 
                    position='top right' size='mini' 
                    trigger={
                      <Icon 
                        style={{ cursor: 'pointer'}} 
                        name="attach" 
                        color="black" 
                        size="large"
                        onClick = {() => {
                          this.setState({
                            ...this.state,
                            openFilePond: true
                          })
                        }}
                    />
                  } />
                  {this.state.openFilePond && (
                    <div style={{ marginTop: '5px'}}>
                      <FilePond //re-write file pond backend hit only on button click
                        allowMultiple={true}
                        maxParallelUploads={5}
                        name='requestImages'
                        server={`/upload`}
                        onupdatefiles={fileItems => this.handleFilePondFiles(fileItems)}
                      />
                    </div>
                  )}
                  <TextArea
                    style={{ marginTop: '5px'}}
                    name="personalizedInstructions" 
                    placeholder='Explain request in detail (optional)' 
                    rows={3} 
                    onChange={this.handleInputChange}
                    value={this.state.personalizedInstructions}
                  />
                </Form.Field>
                <Button size="small" primary onClick={this.onSendRequest}>Request</Button>
                <Icon style={{ float: 'right', cursor: 'pointer'}} 
                  name='map outline' 
                  size='big'
                  onClick = {async () => {
                    await this.fetchLeafletRequest(this.state.signedInEmail);
                    this.setState({
                      ...this.state,
                      view: 'map'
                    })
                  }}
                />
                <Icon style={{ float: 'right', cursor: 'pointer', marginRight: '15px'}} 
                  name='calendar alternate outline' 
                  size='big'
                  onClick = {() => {
                    this.setState({
                      ...this.state,
                      view: 'calendar'
                    })
                  }}
                />
              </Form>
              {this.state.openConfirmationModal && <RequestConfirmationModal 
                openModal={true} 
                parentCallback={this.handleRequestConfirmation} 
                parentState={this.state}/>}
          </Grid>
            {this.state.view==='calendar' && (
              <div style={{ marginTop: '40px', marginLeft: '-2%'}} dangerouslySetInnerHTML={{ __html: mobileIframe}} />
            )}
            {this.state.view==='map' && (
              <div style={{ marginTop: '39px'}}>
                <h4 style={{ marginBottom: '3px', fontWeight: 'bold', fontSize: '16px'}}>Waypoints</h4>
                <LeafletMap
                  selectedAddressLatLng={this.state.selectedAddressLatLng}
                  recentConfirmedRequest={this.state.recentConfirmedRequest}
                />
              </div>
            )}
            {!this.state.isSignedInEmailAnAdmin && this.state.toggledNewsModal && (
              this.renderNewsModal()
            )}
            {this.state.toggledGotchasModal && (
              this.renderGotchasModal()
            )}
          </div>
        )}
        {!isMobile && (
          <Grid divided='vertically'>
            <Grid.Row columns={2}>
              <Grid.Column>
                <Form>
                <Form.Group widths='equal'>
                  <Popup content='Begin typing to add new pick up location' trigger={
                    <Form.Field width={1} error={this.state.error}>
                      <label>From</label>
                      <Select
                        name = 'pickUp'
                        placeholder='Pick up'
                        value={this.state.pickUp}
                        options={this.state.pickUpOptions} 
                        onChange={this.handleChange}
                        search
                        allowAdditions
                        onAddItem={this.handlePickUpAddition}
                      />
                    </Form.Field>
                  } />
                  <Popup content='Begin typing to add new drop off location' trigger={
                    <Form.Field width={4} error={this.state.error}>
                      <label>To</label>
                      <Dropdown 
                        name = 'dropOff'
                        placeholder='Drop-off' 
                        fluid 
                        multiple 
                        selection
                        search
                        options={this.state.dropOffOptions} 
                        onChange={this.handleChange}
                        allowAdditions
                        onAddItem={this.handleDropOffAddition}
                      />
                    </Form.Field>
                  } />
                </Form.Group>
                <Form.Group widths='equal'>
                  <Form.Field width={2} error={this.state.error}>
                    <label>Passengers</label>
                    <Input type="number" id="capacity" 
                      name="capacity" min="1" max="6"
                      value={this.state.capacity}
                      onChange={this.handleInputChange}
                      />
                  </Form.Field>
                  <Form.Field width={5} error={this.state.error}>
                    <label for="requestDate">Approximate timing (Pacific Time)</label>
                      <input type="datetime-local" id="requestDate" min={currentDateTime.split(' ')[0]}
                    name="requestDate" value={this.state.requestDate} onChange={this.handleInputChange}/>
                  </Form.Field>
                  <Popup content='Trailer hitch for the van' trigger={
                    <Form.Field width={1} style={{marginLeft: "5%"}} error={this.state.error} inline>
                      <label>Trailer required?</label>
                      <br />
                      <div style={{ marginTop: '10px'}}>
                        <input type="radio" id="true" name="hitchRequired" 
                          value="true" onChange={this.handleInputChange}/>
                          <Label for="hitch">yes</Label>
                          <br/>
                        <input type="radio" id="false" name="hitchRequired" 
                        value="false" onChange={this.handleInputChange} defaultChecked
                        style={{ marginTop: '10px'}}/>
                          <Label for="hitch">no</Label> 
                      </div>
                    </Form.Field>
                  } />
                </Form.Group>
                  <Form.Field inline>
                    <label>Personalized instructions</label>
                    <Icon 
                      style={{ cursor: 'pointer'}} 
                      name="attach" 
                      color="black" 
                      size="large"
                      onClick = {() => {
                        this.setState({
                          ...this.state,
                          openFilePond: true
                        })
                      }}
                    />
                    {this.state.openFilePond && (
                      <div style={{ marginTop: '5px'}}>
                        <FilePond //re-write file pond backend hit only on button click
                          allowMultiple={true}
                          maxParallelUploads={5}
                          name='requestImages'
                          server={`/upload`}
                          onupdatefiles={fileItems => this.handleFilePondFiles(fileItems)}
                        />
                      </div>
                    )}
                    <TextArea
                      style={{ marginTop: '5px'}}
                      name="personalizedInstructions" 
                      placeholder='Explain request in detail (optional)' 
                      rows={3} 
                      onChange={this.handleInputChange}
                      value={this.state.personalizedInstructions}
                    />
                  </Form.Field>
                  <Button size="small" primary onClick={this.onSendRequest}>Request</Button>
                  <Icon style={{ float: 'right', cursor: 'pointer'}} 
                    name='map outline' 
                    size='big'
                    onClick = {async () => {
                      await this.fetchLeafletRequest(this.state.signedInEmail);
                      this.setState({
                        ...this.state,
                        view: 'map'
                      })
                    }}
                  />
                  <Icon style={{ float: 'right', cursor: 'pointer', marginRight: '15px'}} 
                    name='calendar alternate outline' 
                    size='big'
                    onClick = {() => {
                      this.setState({
                        ...this.state,
                        view: 'calendar'
                      })
                    }}
                  />
                </Form>
                {this.state.openConfirmationModal && <RequestConfirmationModal 
                  openModal={true} 
                  parentCallback={this.handleRequestConfirmation} 
                  parentState={this.state}/>}
              </Grid.Column>
              <Grid.Column>
              {/* {this.state.selectedAddressLatLng.length>1 && (
                <LeafletMap 
                  selectedAddressLatLng={this.state.selectedAddressLatLng}
                  recentConfirmedRequest={this.state.recentConfirmedRequest}
                  />
              )} */}
              {this.state.view==='calendar' && (
                <div dangerouslySetInnerHTML={{ __html: iframe}} />
              )}
              {this.state.view==='map' && (
                <LeafletMap 
                selectedAddressLatLng={this.state.selectedAddressLatLng}
                recentConfirmedRequest={this.state.recentConfirmedRequest}
                />
              )}
              {!this.state.isSignedInEmailAnAdmin && this.state.toggledNewsModal && (
                this.renderNewsModal()
              )}
              {this.state.toggledGotchasModal && (
                this.renderGotchasModal()
              )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      <br />
        {this.state.signedInEmail &&
          (<RequestsTablePage
            isAdmin={this.state.isSignedInEmailAnAdmin}
            signedInEmail={this.state.signedInEmail}/>)}
        {this.state.openNotSignedInModal && 
          (<NotSignedInModal 
            parentCallback={this.handleSignedIn}/>)}
        {this.state.openOTPVerificationModal && 
          (<OTPVerificationModal 
            parentCallback={this.handlePhoneVerification}
            parentState={this.state}/>)}
      </Segment>
      
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  console.log('inside mapStateToProps', state)
  return {user: state}
}

export default connect(mapStateToProps)(PageGrid);
