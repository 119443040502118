import history from '../history';
import axios from 'axios';
import {
  SIGN_IN, 
  SIGN_OUT, 
  CREATE_STREAM,
  CREATE_REQUEST,
  FETCH_USER,
  FETCH_STREAMS,
  FETCH_STREAM,
  DELETE_STREAM,
  EDIT_STREAM  
} from './types';

//action creators returned to authReducer
export const signIn = (userId, userName, userAvatar, userEmail) => {
  return {
    type: SIGN_IN,
    payloadId: userId,
    payloadName: userName,
    payloadAvatar: userAvatar,
    payloadEmail: userEmail
  }
}

export const signOut = () => {
  return {
    type: SIGN_OUT
  }
}

export const createRequest = formValues => async (dispatch, getState) => {
  const { userEmail } = getState().auth;
  const response = await axios.post('/request', {...formValues, userEmail});

  dispatch({ type: CREATE_REQUEST, payload: response })
  //navigate to root route
  //history.push('/');
};

export const fetchStreams = () => async dispatch => {
  const response = await axios.get('/streams')

  dispatch({ type: FETCH_STREAMS, payload: response })
};

export const fetchUser = (id) => async dispatch => {
  console.log('inside fetchUser', id)
  const response = await axios.get(`/users/${id}`)
  console.log('after fetch user')

  dispatch({ type: FETCH_USER, payload: response })
}

export const editStream = (id, formValues) => async dispatch => {
  const response = await axios.patch(`/streams/edit/${id}`, formValues);

  dispatch({ type: EDIT_STREAM, payload: response});
  history.push('/');
}

export const deleteStream = (id) => async dispatch => {
  await axios.delete(`/streams/${id}`);

  dispatch({ type: DELETE_STREAM, payload: id});
  history.push('/');
}