import axios from 'axios';
import React, { Component } from 'react'
import { Button, Modal, Icon, Header, Form, Input, Message } from 'semantic-ui-react'

class AddDropOffLocationModal extends React.Component {
    state = {
        modalOpen: false,
        address: [""],
        location: "",
        preparedAddressArray: [],
        error: false
    };

    handleClose = () => {
        this.setState({ 
            modalOpen: false,
            address: [""],
            location: "",
            preparedAddressArray: []
        })
    }

    handleModalOpen = () => this.setState({ 
        modalOpen: true
    });

    addNewLocation = async () => {
        //15555 Hesperian Blvd, San Leandro, CA 94579
        //30600 Dyer St, Union City, CA 94587
        let preparedAddressArray = []
        let addressArray = this.state.address;

        //if(addressArray.length === 1 || !this.state.location) {
        if(!this.state.location) {
            this.setState({
                ...this.state,
                error: true
            })
        } else {
            for(let address of addressArray) {
                let latLngObject = await this.fetchLatLng(address);
                latLngObject.name = address;
                preparedAddressArray.push(latLngObject);
            }
            console.log('preparedAddressArray', preparedAddressArray)
            this.setState({
                modalOpen: false,
                preparedAddressArray
            }, () => {
                this.props.parentCallback(this.state);
            }, () => {
                this.setState({
                    ...this.state,
                    modalOpen: false,
                    address: [""],
                    location: "",
                    preparedAddressArray: []
                })
            })
        }
    }

    fetchLatLng = async(address) => {
        let response = await axios.get(`https://nominatim.openstreetmap.org/?addressdetails=1&q=${address}&format=json&limit=1`, {
        })
        .catch(err => 
            console.log(err))
        console.log('response from nominatim', response);

        if(response.data.length>0) {
            return {
                lat: response.data[0].lat,
                lng: response.data[0].lon
            }
        } else {
            return {
                lat: '',
                lng: ''
            }
        }
    }

    handleInputChange = i => e => {
        let address = [...this.state.address]
        address[i] = e.target.value
        this.setState({
            address,
            error: false
        })
    }

    handleDelete = i => e => {
        e.preventDefault();
        let address = [
            ...this.state.address.slice(0, i),
            ...this.state.address.slice(i + 1)
        ]
        this.setState({
            address,
            error: false
        })
    }

    addNewAddress = e => {
        e.preventDefault()
        let address = this.state.address.concat([''])
        this.setState({
            address,
            error: false
        })
    }

    handleLocationChange = (e) => {
        this.setState({
          ...this.state,
          [e.target.name]: e.target.value,
          error: false
        })
    }

    render() {
        console.log('state in dropofflocmod', this.state);
        return (
            <Modal
                trigger={
                    <Icon style={{ marginLeft: '15px', marginTop: '-5px', cursor: 'pointer'}} name="plus circle" color="green" size="large"/>
                }
                closeIcon
                onOpen={this.handleModalOpen}
                onClose={this.handleClose}
                open={this.state.modalOpen}
                >
                <Header content='Add Drop off location' />
                <Modal.Content>
                    <Form>
                        <label><b>Location</b></label>
                        <Input
                            name="location"
                            onChange={this.handleLocationChange}
                            //value={this.state.location}
                            style={{ marginLeft: '22px', width: '35%'}}
                            required
                        />
                        <Form.Field inline />
                        {this.state.address.map((address, index) => (
                            <span key={index}>
                                <Form.Field inline />
                                <label style={{ marginTop: '3px'}}><b>Addresses</b></label>
                                <Input
                                    name="address"
                                    onChange={this.handleInputChange(index)}
                                    //value={address}
                                    style={{ marginLeft: '10px', width: '50%'}}
                                    required
                                />
                                <Icon onClick={this.handleDelete(index)} 
                                    style={{ marginLeft: '15px', cursor: 'pointer'}} 
                                    name="minus circle" 
                                    color="red" 
                                    size="large"
                                />
                            </span>
                        ))}
                        <Icon onClick={this.addNewAddress} 
                            style={{ marginLeft: '15px', cursor: 'pointer'}} 
                            name="plus circle" 
                            color="green" 
                            size="large"
                        />
                    </Form>
                    {this.state.error && (
                        <Message warning style={{ textAlign: 'center'}}>
                            <Message.Header>Field values cannot be left empty</Message.Header>
                        </Message>
                    )}
                </Modal.Content>
                <Modal.Actions>
                    <Button color='red' onClick={this.handleClose}>
                        <Icon name='remove' /> No
                    </Button>
                    <Button color='green' onClick={this.addNewLocation}>
                        <Icon name='checkmark' /> Yes
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default AddDropOffLocationModal;