import React, { Component } from 'react'
import { Button, Modal, Icon, Header, Form } from 'semantic-ui-react'
import axios from 'axios'
import { connect } from "react-redux";

class RejectDecisionModal extends React.Component {
    state = {
        modalOpen: false,
        requestId: null,
        reason: "",
        request_decision_author_email: "",
        request_decision_author_name: "",
        error: false
    };

    componentDidMount = () => {
        this.setState({
            ...this.state,
            modalOpen: false
        })
    }

    componentWillReceiveProps = () => {
        this.setState({
            ...this.state,
            requestId: this.props.requestId,
            request_decision_author_email: this.props.user.auth.userEmail,
            request_decision_author_name: this.props.user.auth.userName
        })
    }

    handleOpen = () => this.setState({ modalOpen: true });

    handleClose = () => {
        this.setState({ modalOpen: false });
        this.props.parentCallback();
    }

    handleInputChange = (e) => {
        console.log(e.target.name)
        console.log(e.target.value)
        this.setState({
          ...this.state,
          [e.target.name]: e.target.value,
          error: false
        })
    }

    handleDecisionRejectance = async() => {
        if(!this.state.reason) {
            this.setState({
                ...this.state,
                error: true
            })
        } else {
            let requestObject = {
                requestId: this.state.requestId,
                reason: this.state.reason,
                request_decision_author_name: this.state.request_decision_author_name,
                request_decision_author_email: this.state.request_decision_author_email
            }
            const response = await axios.post('/request/decline', requestObject);
            console.log('response from accept', response)
            this.setState({ modalOpen: false })
            this.props.parentCallback();
        }
    }

    render() {
        // console.log('props in modal', this.props)
        console.log('state in rejct decs modal', this.state)
        return (
            <div>
                <Modal
                    closeIcon
                    open={this.props.show}
                    onClose={this.handleClose}
                    >
                    <Header content='Confirm decision?' />
                    <Modal.Content>
                        <p>
                            Are you sure you want to decline this request?
                        </p>
                        <Form>
                            <Form.TextArea 
                                error={this.state.error}
                                onChange={this.handleInputChange}
                                name="reason"
                                label="Reason"
                                placeholder='In case your schedule is packed for the day or some unforseen circumstances' />
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='red' onClick={this.handleClose}>
                        <Icon name='remove' /> No
                        </Button>
                        <Button color='green' onClick={this.handleDecisionRejectance}>
                        <Icon name='checkmark' /> Yes
                        </Button>
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    console.log('mapstateprops in reject decision modal', state)
    return {
      user: state
    }
  }
  
  export default connect(mapStateToProps)(RejectDecisionModal);