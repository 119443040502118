import axios from 'axios';
import React, { Component } from 'react'
import { Button, Modal, Icon, Header, Form, Input } from 'semantic-ui-react'

class AddAdminEmailModal extends React.Component {
    state = {
        modalOpen: false,
        email: "",
        error: false
    };

    handleClose = () => {
        this.setState({ modalOpen: false })
    }

    handleModalOpen = () => this.setState({ modalOpen: true });

    handleInputChange = (e) => {
        console.log(e.target.name)
        console.log(e.target.value)
        this.setState({
          ...this.state,
          [e.target.name]: e.target.value,
          error: false
        })
    }

    addNewLocation = () => {
        console.log('inside addNewLocation')
        if(!this.state.email) {
            this.setState({
                ...this.state,
                error: true
            })
        } else {
            this.setState({
                modalOpen: false 
            })
            this.props.parentCallback(this.state.email);
        }
    }

    render() {
        return (
            <Modal
                trigger={
                    <Icon style={{ marginLeft: '15px', marginTop: '-5px', cursor: 'pointer'}} name="plus circle" color="green" size="large"/>
                }
                closeIcon
                onOpen={this.handleModalOpen}
                onClose={this.handleClose}
                open={this.state.modalOpen}
                >
                <Header content='Add email to admin' />
                <Modal.Content>
                    <Form>
                        <Form.Field inline error={this.state.error}>
                            <label ><b>Email</b></label>
                            <Input
                                name="email"
                                onChange={this.handleInputChange}
                                style={{ marginLeft: '10px'}}
                            />
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='red' onClick={this.handleClose}>
                        <Icon name='remove' /> No
                    </Button>
                    <Button color='green' onClick={this.addNewLocation}>
                        <Icon name='checkmark' /> Yes
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default AddAdminEmailModal;