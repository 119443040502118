import React, { Component } from 'react'
import { Button, Modal, Icon, Header } from 'semantic-ui-react'
import { fetchUser, createRequest } from '../../actions'
import { connect } from "react-redux";

class RequestConfirmationModal extends React.Component {
    // TO-DO add .then to handlesubmission
    
    state = {
        modalOpen: false,
        parentState: {},
        requestSubmissionConfirmed: false
    };

    componentDidMount = () => {
        this.setState({
            ...this.state,
            modalOpen: true,
            parentState: this.props.parentState
        })
    }

    handleOpen = () => this.setState({ modalOpen: true });

    handleClose = () => {
        this.setState({ modalOpen: false })
        this.props.parentCallback(false);
    }

    handleSubmissionConfirmation = async() => {
        let authComponent = this.props.user.auth;
        let stateComponent = this.state.parentState;
        let requestComponent = {stateComponent, authComponent}
        //console.log('requestComponent', requestComponent)
        await this.props.createRequest(requestComponent)
        this.setState({
            ...this.state,
            // modalOpen: false,
            requestSubmissionConfirmed: true
        })
        //await this.props.parentCallback(false);

        setTimeout(function() {
            document.location.reload()
        }, 2000);
    }

    render() {
        // console.log('props in modal', this.props)
        // console.log('state in modal', this.state)
        return (
            <div>
            <Modal
                open={this.state.modalOpen}
                onClose={this.handleClose}
                closeIcon
                >
                
                {!this.state.requestSubmissionConfirmed && (
                    <>
                        <Modal.Header>Confirm submission?</Modal.Header>
                        <Modal.Content>
                            <p>
                                Are you sure you want to submit this request?
                            </p>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button color='red' onClick={this.handleClose}>
                                <Icon name='remove' /> Cancel
                            </Button>
                            <Button color='green' onClick={this.handleSubmissionConfirmation}>
                                <Icon name='checkmark' /> Confirm
                            </Button>
                        </Modal.Actions>
                    </>
                )}
                {this.state.requestSubmissionConfirmed && (
                    <Modal.Content>
                    <h2 style={{ textAlign: 'center'}}>
                        Request Sent! <Icon color="green" name="check circle" />
                    </h2>
                    <div style={{ textAlign: 'center'}}>
                        You will be recieving whatsapp notifications to the number you signed up with
                    </div>
                    </Modal.Content>
                )}
            </Modal>
        </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {user: state}
}

export default connect(mapStateToProps, {fetchUser, createRequest})(RequestConfirmationModal);