import axios from 'axios';
import React, { Component } from 'react'
import { Button, Modal, Icon, Header, Form, Input } from 'semantic-ui-react'

class AddPickUpLocationModal extends React.Component {
    state = {
        modalOpen: false,
        location: "",
        addressName: "",
        addressLat: "",
        addressLong: "",
        error: false
    };

    handleClose = () => {
        this.setState({ modalOpen: false })
    }

    handleModalOpen = () => this.setState({ modalOpen: true });

    handleInputChange = (e) => {
        console.log(e.target.name)
        console.log(e.target.value)
        this.setState({
          ...this.state,
          [e.target.name]: e.target.value,
          error: false
        })
    }

    addNewLocation = async () => {
        console.log('inside addNewLocation')
        if(!this.state.location || !this.state.addressName) {
            console.log('address is empty')
            this.setState({
                ...this.state,
                error: true
            })
        } else {
            await this.fetchLatLng(this.state.addressName);
            this.setState({
                modalOpen: false 
            })
            this.props.parentCallback(this.state);
        }
    }

    fetchLatLng = async(address) => {
        let response = await axios.get(`https://nominatim.openstreetmap.org/?addressdetails=1&q=${address}&format=json&limit=1`, {
        })
        .catch(err => 
            console.log(err))
        console.log('response from nominatim', response);

        this.setState({
            addressLat: response.data[0].lat,
            addressLong: response.data[0].lon
        })
    }

    render() {
        return (
            <Modal
                trigger={
                    <Icon 
                    style={{ marginLeft: '15px', marginTop: '-5px', cursor: 'pointer'}} 
                    name="plus circle" color="green" size="large"/>
                }
                closeIcon
                onOpen={this.handleModalOpen}
                onClose={this.handleClose}
                open={this.state.modalOpen}
                >
                <Header content='Add Pick up location' />
                <Modal.Content>
                    <Form>
                        <Form.Field inline error={this.state.error}>
                            <label ><b>Location</b></label>
                            <Input
                                name="location"
                                onChange={this.handleInputChange}
                                style={{ marginLeft: '10px'}}
                            />
                        </Form.Field>
                        
                        <Form.Field inline error={this.state.error}>
                            <label ><b>Address</b></label>
                            <Input
                                name="addressName"
                                onChange={this.handleInputChange}
                                style={{ marginLeft: '10px'}}
                            />
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='red' onClick={this.handleClose}>
                        <Icon name='remove' /> No
                    </Button>
                    <Button color='green' onClick={this.addNewLocation}>
                        <Icon name='checkmark' /> Yes
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default AddPickUpLocationModal;