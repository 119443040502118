import React from 'react';
import { useChecklist } from 'react-checklist';
import { Button } from 'semantic-ui-react'

// const data = [
//   { _id: 1, label: 'item 1', value: true },
//   { _id: 2, label: 'item 2', value: true },
//   { _id: 3, label: 'item 3', value: true },
// ]

// function prepareCheckListData (checkList) {
//     let preparedCheckList = []
//     for(let list of checkList) {
//         if(list.checked === "yes")
//         preparedCheckList.push(list.id)
//     }
//     return preparedCheckList;
// }

function updateChecklist(checkedItems, checkListData) {
    //e.preventDefault();
    console.log('inside updateChecklist method')
    console.log('checkedItems', checkedItems);
    console.log('checkListData', checkListData)
    let preparedCheckList = []
    for(let id of checkedItems) {
        //console.log('in iteration', list)
        let object = {};
        object.id = id;
        object.description = checkListData[parseInt(id)].description;
        object.checked = "yes";

        preparedCheckList.push(object);
    }
    console.log('preparedCheckList', preparedCheckList)
}

export default (props) => {
    console.log('props in checklist', props.checklistValue)
    //let data = prepareCheckListData(props.checklistValue);
    let data = props.checklistValue
    const { handleCheck, isCheckedAll, checkedItems } = useChecklist(data, {
        key: 'id',
        keyType: 'string',
    });
    console.log('checklist items', checkedItems);
    return (
        <>
            <ul>
                <li>
                    <input
                        type="checkbox"
                        onChange={handleCheck}
                        checked={isCheckedAll}
                    />
                    <label>Check All</label>
                </li>

                {data.map((v, i) => (
                    <li key={i}>
                    <input
                        type="checkbox"
                        data-key={v.id}
                        onChange={handleCheck}
                        checked={checkedItems.has(v.id)}
                    />
                    <label>{v.description}</label>
                    </li>
                ))}
            </ul>
            <div style={{ textAlign: 'center', marginTop: '20px'}}>
                <Button size="small" 
                    primary 
                    //onClick={updateChecklist(checkedItems, data)}
                    onClick={() => updateChecklist(checkedItems, data)}
                >Save</Button>
            </div>
        </>
    );
};