import { Table, Header, Button, Icon, Label, Popup, Modal } from 'semantic-ui-react'
import { Component } from 'react'
import moment from 'moment'
import { connect } from "react-redux";
import ViewDecisionModal from './modals/ViewDecisionModal';
import axios from 'axios'

class RequestsTablePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requests: [],
      signedInEmail: null,
      isAdmin: false,
      modalOpen: true
    }
  }

  componentDidMount = () => {
    console.log('inside component did mount of request table', this.props.signedInEmail)
    this.setState({
      ...this.state,
      signedInEmail: this.props.signedInEmail
    }, () => {
      this.fetchRequests();
    })
  }

  fetchRequests = async() => {
    console.log('inside fetch requests')
    let pastRequests = await axios.get(`/request/${this.state.signedInEmail}`)
    console.log('pastRequests', pastRequests)
    this.setState({
      ...this.state,
      requests: pastRequests.data,
      isAdmin: this.props.isAdmin
    })
  }

  cleanDate(date) {
    var cleanDateFormat = "";
    if (date) {
      cleanDateFormat = moment(date).format("Do MMM hh:mm A");
    }
    return cleanDateFormat;
  }

  handleClose = () => {
    this.setState({
      ...this.state,
      modalOpen: false
    })
  }

  render() {
    console.log('state in requests table', this.state)
    console.log('props in req table', this.props)
    let requests = this.state.requests;
    let authComponent = this.props.user.auth;
    let signedInEmail = authComponent.userEmail;
    console.log('signedInEmail in req table', signedInEmail);

    let isMobile = false; //initiate as false
    // device detection
    if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) { 
        isMobile = true;
    }
    return (
        // <Grid>
        // <Grid.Row columns={1} >
        //   <Grid.Column>
        <div>
          {this.state.isAdmin && (
            <Modal
              closeIcon
              open={this.state.modalOpen}
              onClose={this.handleClose}
              >
              <Modal.Header>Admin actions</Modal.Header>
              <Modal.Content>
                <ul>
                  <li>To Approve or Deny requests, please follow to the <b>Decisions</b> page.</li>
                  <li>To Update settings, please follow to the <b>Settings</b> page.</li>
                </ul>
                <div style={{ marginTop: '10px', marginLeft: '30px'}}>
                  <Button 
                    onClick={() => {window.location.href="/decisions"}} 
                    primary size='small' 
                    // style={{ display: 'inline'}} 
                    >
                    Decisions
                  </Button>
                  <Button 
                    onClick={() => {window.location.href="/settings"}}
                    color='black'
                    size='small' 
                    style={{ marginLeft: '15px'}} 
                    >
                    Settings
                  </Button>
                </div>
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={this.handleClose}>
                  Close
                </Button>
            </Modal.Actions>
          </Modal>
          )}
          {!this.state.isAdmin && (
            <div style={{ marginTop: '4%'}}>
              <Header as='h4' 
                style={{ display: 'inline', marginTop: '2%', position: 'absolute'}}>
                  Recent requests
              </Header>
              <Button animated primary size='small' 
                style={{ display: 'inline', float: 'right', marginBottom: '1%'}} 
                onClick={this.fetchRequests}>
                <Button.Content visible>Refresh</Button.Content>
                <Button.Content hidden>
                  <Icon name='refresh' />
                </Button.Content>
              </Button>
            </div>
          )}
          {!this.state.isAdmin && (<Table unstackable 
            style={{ 
              marginTop: '20px',
              tableLayout: 'fixed',
              width: '100%'
            }}>
            <Table.Header>
              <Table.Row>
                {/* <Table.HeaderCell>From</Table.HeaderCell> */}
                <Table.HeaderCell>To</Table.HeaderCell>
                <Table.HeaderCell>Date</Table.HeaderCell>
                {!isMobile && (<Table.HeaderCell>Passengers</Table.HeaderCell>)}
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Action</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
                {requests.length === 0 && !isMobile && (
                    <Table.Row>
                      <Table.Cell colSpan="5">
                          <center>No requests yet</center>
                      </Table.Cell>
                    </Table.Row>
                )}
                {requests.length === 0 && isMobile && (
                    <Table.Row>
                      <Table.Cell colSpan="4">
                          <center>No requests yet</center>
                      </Table.Cell>
                    </Table.Row>
                )}
                {requests.length > 0 && 
                  Object.keys(requests).map(key => (
                    // <Popup content='You can request cancellation via whatsapp, the driver will be able to fulfill this cancellation request' trigger={
                      <Table.Row key={key}>
                          {/* <Table.Cell style={{ wordWrap: "break-word"}}>
                              <Label ribbon>{requests[key].request_decision_author_name.split(' ')[0]}</Label>
                              {requests[key].pick_up}
                          </Table.Cell> */}
                          <Table.Cell style={{ wordWrap: "break-word"}}>
                            <Label ribbon>{requests[key].request_decision_author_name.split(' ')[0]}</Label>
                              {requests[key].drop_off.join(", ")}
                          </Table.Cell>
                          <Table.Cell style={{ wordWrap: "break-word"}}>
                            {this.cleanDate(requests[key].request_date)}
                          </Table.Cell>
                          {!isMobile && (<Table.Cell style={{ wordWrap: "break-word"}}>
                            {requests[key].capacity}
                          </Table.Cell>)}
                          
                          <Table.Cell style={{ wordWrap: "break-word"}}>
                            {requests[key].request_status==='pending' && (
                              <Label color='yellow'>
                                {requests[key].request_status}
                              </Label>
                            )}
                            {requests[key].request_status==='confirmed' && (
                              <Label color='green'>
                                {requests[key].request_status}
                              </Label>
                            )}
                            {requests[key].request_status==='declined' && (
                              <Label color='red'>
                                {requests[key].request_status}
                              </Label>
                            )}
                            {requests[key].request_status==='cancelled' && (
                              <Label color='grey'>
                                {requests[key].request_status}
                              </Label>
                            )}
                            {requests[key].request_status==='completed' && (
                              <Label color='black'>
                                {requests[key].request_status}
                              </Label>
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            <ViewDecisionModal 
                              request={requests[key]}
                              parentCallback={this.fetchRequests} 
                            />
                          </Table.Cell>
                      </Table.Row>
                    // } />
                  ))
                }
            </Table.Body>
          </Table>)}
          {/* </Grid.Column>
        </Grid.Row>
        </Grid> */}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {user: state}
}

export default connect(mapStateToProps)(RequestsTablePage);
