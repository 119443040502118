import React from 'react';
import {connect} from 'react-redux';
import {signIn, signOut} from '../actions';
import { Icon, Modal, Input, Button, Form, Message, TextArea, Select } from 'semantic-ui-react';
import axios from 'axios'

class GoogleAuth extends React.Component {
  state = {
    modalOpen: false,
    userName: "",
    userEmail: "",
    residence: "",
    building_number: "",
    appartment_number: "",
    address: "",
    saved: false,
    isMobile: false,
    residenceOptions: [],
    error: false
  };

  componentDidMount() {
    let isMobile = false; //initiate as false
    if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
      || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) { 
      isMobile = true;
    }
    this.setState({
      ...this.state,
      isMobile,
      residenceOptions: [
        { key: 'cv', value: 'City view appartments', text: 'City view appartments' },
        { key: 'sh', value: 'Sunhill appartments', text: 'Sunhill appartments' },
        { key: 'ps', value: 'Parkside appartments', text: 'Parkside appartments' }
      ]
    })

    window.gapi.load('client:auth2', () => {
      window.gapi.client.init({
        clientId: '67906956523-tg19i31knr205lpjprhgmlg4fcc6qteg.apps.googleusercontent.com',
        scope: 'email'
      }).then(() => {
        this.auth = window.gapi.auth2.getAuthInstance();

        this.onAuthChange(this.auth.isSignedIn.get()); //calling onAuthChange passing a boolean parameter of signedIn
        this.auth.isSignedIn.listen(this.onAuthChange);
      })
    })
  }

  onAuthChange = async (isSignedIn) => {
    //if true, call signIn action creator passing userId
    if(isSignedIn) {
      //console.log("user deets", this.auth.currentUser.get().getBasicProfile())
      let userId = this.auth.currentUser.get().getId();
      let userName = this.auth.currentUser.get().getBasicProfile().getName();
      let userEmail = this.auth.currentUser.get().getBasicProfile().getEmail();
      let userAvatar = this.generateAvatar(userName);
      this.props.signIn(userId, userName, userAvatar, userEmail);

      let fetchedUser = await axios.get(`/users/${userEmail}`);
      if(!fetchedUser.data.emailId) {
        this.handleOpen();
        this.setState({
          ...this.state,
          userName,
          userEmail
        })
      }
    } else {
      this.props.signOut();
    }
  };

  generateAvatar = (userName) => {
    let firstInitial = userName.charAt(0);
    let whiteSpaceIndex = userName.indexOf(' ')
    let secondInitial = userName.charAt(whiteSpaceIndex+1);
    return firstInitial+secondInitial;
  };

  onSignInClick = async() => {
    await this.auth.signIn();
    window.location.reload();
  };

  onSignOutClick = async() => {
    let pathInURL = window.location.pathname;

    //prevent loading issue
    if(pathInURL === "/decisions" || pathInURL === "/settings") {
      await this.auth.signOut();
      window.location.href = "/";
    } else {
      await this.auth.signOut();
      window.location.reload();
    }
  };

  fetchUser = async(email) => {
    console.log('fetching user details of', email)
    const response = await axios.get(`/users/${email}`);
    //console.log('response from user fetch', response)
    if(response.data) {
      this.setState({
        ...this.state,
        userName: response.data.name,
        userEmail: email,
        residence: response.data.residence,
        building_number: response.data.building_number,
        appartment_number: response.data.apartment_number,
        address: response.data.complete_address
      })
    } else {
      this.setState({
        ...this.state,
        userEmail: email
      })
    }
  }

  handleOpen = async () => {
    //console.log('inside handleOpen', this.props);
    await this.fetchUser(this.props.signedInUserEmail);
    this.setState({ 
      modalOpen: true,
      saved: false
    });
  }

  handleClose = () => {
    this.setState({ modalOpen: false })
  }

  handleInputChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
      error: false
    })
  }

  handleSave = async() => {
    if(this.state.residence && this.state.building_number && this.state.appartment_number && this.state.address) {
      const response = await axios.post('/users', this.state)
      console.log('reponse inside handleSave', response)
      this.setState({
        ...this.state,
        saved: true
      }, () => {
        setTimeout(function() {
          document.location.reload()
        }, 500);
      })
    } else {
      this.setState({
        ...this.state,
        error: true
      })
    }
  }

  handleChange = (e, data) => {
    console.log('data.value in handleChange', data.value)
    this.setState({
      ...this.state,
      [data.name]: data.value,
      error: false
    })
  }

  handleResidenceAddition = (e, { value }) => {
    //console.log('value in handleResidenceAddition', value)
    this.setState((prevState) => ({
      residenceOptions: [{ text: value, value }, ...prevState.residenceOptions],
    }))
  }

  renderModal = () => {
    return (
      <Modal 
      trigger= {
        <Icon 
          style={{ cursor: 'pointer', display: 'inline', marginRight: '8px'}} 
          name="user outline" color="black"
          onClick={ () => {
            this.handleOpen()
          }}
          open={this.state.modalOpen}
        />
      }
      open={this.state.modalOpen}
      onClose={this.handleClose}
      >
        <Modal.Header>{this.state.userName}</Modal.Header>
        <Modal.Content>
          {this.state.isMobile && (
            <Form>
              <Form.Field inline error={this.state.error} required>
                  <label>Residence</label>
                  <Select
                    style={{width: '50%', marginLeft: '14%'}}
                    name = 'residence'
                    placeholder='Select or add a residence' 
                    options={this.state.residenceOptions} 
                    onChange={this.handleChange}
                    search
                    allowAdditions
                    onAddItem={this.handleResidenceAddition}
                    value={this.state.residence}
                  />
              </Form.Field>
              <Form.Field inline error={this.state.error} required>
                  <label>Building number</label>
                  <Input name="building_number" style={{width: '30%', marginLeft: '4%'}}
                    value={this.state.building_number}
                    onChange={this.handleInputChange}
                    placeholder='Enter building number'
                  />
              </Form.Field>
              <Form.Field inline error={this.state.error} required>
                  <label>Apartment number</label>
                  <Input name="appartment_number" style={{width: '30%'}}
                    value={this.state.appartment_number}
                    onChange={this.handleInputChange}
                    placeholder='Enter appartment number'
                    />
              </Form.Field>
              <Form.Field inline error={this.state.error} required>
                  <label>Address</label>
                  <TextArea name="address" 
                    style={{minHeight: 30, width: '100%'}}
                    value={this.state.address}
                    onChange={this.handleInputChange}
                    placeholder="Enter complete address"
                  />
              </Form.Field>
            </Form>
          )}
          {!this.state.isMobile && (
            <Form>
              <Form.Field inline error={this.state.error} required>
                  <label>Residence</label>
                  <Select
                    style={{width: '30%', marginLeft: '7.5%'}}
                    name = 'residence'
                    placeholder='Select or add a residence' 
                    options={this.state.residenceOptions} 
                    onChange={this.handleChange}
                    search
                    allowAdditions
                    onAddItem={this.handleResidenceAddition}
                    value={this.state.residence}
                  />
              </Form.Field>
              <Form.Field inline error={this.state.error} required>
                  <label>Building number</label>
                  <Input name="building_number" style={{width: '30%', marginLeft: '3%'}}
                    value={this.state.building_number}
                    onChange={this.handleInputChange}
                    placeholder='Enter building number'
                  />
              </Form.Field>
              <Form.Field inline error={this.state.error} required>
                  <label>Apartment number</label>
                  <Input name="appartment_number" style={{width: '30%', marginLeft: '1%'}}
                    value={this.state.appartment_number}
                    onChange={this.handleInputChange}
                    placeholder='Enter appartment number'
                    />
              </Form.Field>
              <Form.Field inline error={this.state.error} required>
                  <label>Address</label>
                  <TextArea name="address" 
                    style={{minHeight: 30, width: '100%'}}
                    value={this.state.address}
                    onChange={this.handleInputChange}
                    placeholder="Enter complete address"
                  />
              </Form.Field>
          </Form>
          )}
        {this.state.saved && (
          <Message
            positive
            icon='check'
            header='Saved'
            content='User profile updated'
          />
        )}
      </Modal.Content>
      <Modal.Actions>
          <Button primary onClick={this.handleSave}>
            Save
          </Button>
      </Modal.Actions>
      </Modal>
    )
  }

  renderAuthButton() {
    if(this.props.isSignedIn === null) {
      return null
    } else if (this.props.isSignedIn) {
      //console.log('props in auth', this.props.userName)
      return (
        <div style={{ display: 'inline'}}>
          {this.renderModal()}
          {!this.state.isMobile && (<p style={{ display: 'inline', marginRight: '20px', cursor: 'pointer'}} 
            onClick={() => {
              this.handleOpen()
            }}>
            {`Welcome, ${this.props.signedInUserName}`}
          </p>)}
          {this.state.isMobile && (<p style={{ display: 'inline', cursor: 'pointer', marginRight: '10px'}}>
            {this.props.signedInUserName}
            </p>
          )}
          
          <div style={{ display: 'inline'}}>
          <button onClick={this.onSignOutClick} className="ui red google button">
          <i className="google icon" />
            Sign Out
          </button>
          </div>
        </div>
      )
    } else {
      return (
        <button onClick={this.onSignInClick} className="ui red google button">
          <i className="google icon" />
            Sign In with Google
        </button>
      )
    }
  }

  render() {
    console.log('state in google auth', this.state)
    return <div style={{ marginTop: '5px', marginBottom: '5px'}}>
      {this.renderAuthButton()}
    </div>;
  }
}

const mapStateToProps = (state) => {
  console.log('mapStateToProps in google auth', state)
  return {
    isSignedIn: state.auth.isSignedIn,
    signedInUserName: state.auth.userName,
    signedInUserEmail: state.auth.userEmail
  }
}

export default connect(mapStateToProps, {signIn, signOut})(GoogleAuth);