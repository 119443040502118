import { Form, Input, Icon, Button, Header, Segment, Dimmer, Loader, Modal, List } from 'semantic-ui-react'
import { Component } from 'react'
import AddPickUpLocationModal from './modals/AddPickUpLocationModal'
import AddDropOffLocationModal from './modals/AddDropOffLocationModal'
import AddAdminEmailModal from './modals/AddAdminEmailModal'
import { connect } from "react-redux";
import axios from 'axios'

class SettingsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pickUpOptions: [],
      dropOffOptions: [],
      adminEmails: [],
      pickUpValues: [],
      dropOffValues: [],
      settingsUpdatedInDB: false,
      loading: true,
      loggedInUserEmail: null,
      isLoggedInUserAnAdmin: false
    }
  }

  async componentWillMount() {
    await this.fetchSettings();
    let pickUpValues = await this.preparePickUpValues()
    let dropOffValues = await this.prepareDropOffValues()
    this.setState({
      ...this.state,
      pickUpValues,
      dropOffValues
    })
  }

  componentDidUpdate = () => {
    if(this.state.loggedInUserEmail===null && this.props.user.auth.userEmail) {
      console.log('loggedinemail is empty')
      let isLoggedInUserAnAdmin;
      if(this.state.adminEmails.includes(this.props.user.auth.userEmail) || this.props.user.auth.userEmail === "dhipauk27@gmail.com") {
        isLoggedInUserAnAdmin = true
      } else {
        isLoggedInUserAnAdmin = false
      }
      this.setState({
        ...this.state,
        loading: false,
        loggedInUserEmail: this.props.user.auth.userEmail,
        isLoggedInUserAnAdmin
      })
    }
  }

  fetchSettings = async() => {
    const response = await axios.get('/settings_route', {});
    console.log('response in fetch setting', response)
    if(response.data.response) {
      this.setState({
        ...this.state,
        pickUpOptions: response.data.response.pick_up_locations,
        dropOffOptions: response.data.response.drop_off_locations,
        adminEmails: response.data.response.admin_emails
      })
    }
  }

  preparePickUpValues = () => {
    let pickUpValues = [];
    for(let object of this.state.pickUpOptions) {
      pickUpValues.push(object.value)
    }
    return pickUpValues;
  }

  prepareDropOffValues = () => {
    let dropOffValues = [];
    for(let object of this.state.dropOffOptions) {
      dropOffValues.push(object.value)
    }
    return dropOffValues;
  }

  addPickUpToState = (locationState) => {
    let newPickUpLocation = locationState.location;
    console.log('inside settings addPickUpToState, newPickUpLocation', newPickUpLocation)
    let newPickUpKey = `${newPickUpLocation[0]}${newPickUpLocation[1]}`
    let newPickUpObject = {}
    newPickUpObject.key = newPickUpKey;
    newPickUpObject.value = newPickUpLocation;
    newPickUpObject.text = newPickUpLocation;

    let addressObject = {}
    addressObject.name = locationState.addressName;
    addressObject.lat = locationState.addressLat;
    addressObject.lng = locationState.addressLong;
    newPickUpObject.address = addressObject;

    console.log('newPickUpObject', newPickUpObject)

    let pickUpOptions = this.state.pickUpOptions;
    pickUpOptions.push(newPickUpObject)

    let pickUpValues = this.state.pickUpValues
    pickUpValues.push(newPickUpLocation)
    this.setState({
      ...this.state,
      pickUpValues,
      pickUpOptions
    })
  }

  addDropOffToState = (addressLoc) => {
    //Build array of objects similair to mongodb
    console.log('addressLoc', addressLoc)
    let newDropOffLocation = addressLoc.location;
    let newDropOffKey = `${newDropOffLocation[0]}${newDropOffLocation[1]}`

    let newDropOffObject = {}
    newDropOffObject.key = newDropOffKey;
    newDropOffObject.value = newDropOffLocation;
    newDropOffObject.text = newDropOffLocation;

    let addressArray = [];
    for(let address of addressLoc.preparedAddressArray) {
      let addressObject = {};
      addressObject.name = address.name;
      addressObject.lat = address.lat;
      addressObject.lng = address.lng;
      addressArray.push(addressObject)
    }
    newDropOffObject.address = addressArray;
    console.log('newDropOffObject', newDropOffObject)

    let dropOffOptions = this.state.dropOffOptions;
    dropOffOptions.push(newDropOffObject)

    let dropOffValues = this.state.dropOffValues
    dropOffValues.push(newDropOffLocation)


    //prepare new location object and add to drop off options
    this.setState({
      ...this.state,
      dropOffValues,
      dropOffOptions
    })
  }

  addAdminEmailToState = (email) => {
    let adminEmails = this.state.adminEmails
    adminEmails.push(email)
    this.setState({
      ...this.state,
      adminEmails
    })
  }

  updateSettings = async() => {
    const response = await axios.post('/settings_route', this.state);
    if(response.data) {
      this.setState({
        ...this.state,
        settingsUpdatedInDB: true
      })
    }
  }

  handleClose = () => {
    this.setState({ settingsUpdatedInDB: false } , async () => {
      await this.fetchSettings();
    })
  }

  handleDropOffLocationDelete = async (index) => {
    let dropOffOptions = this.state.dropOffOptions;
    dropOffOptions.splice(index, 1);

    this.setState({
      ...this.state,
      dropOffOptions
    }, async () => {
      let dropOffValues = await this.prepareDropOffValues()
      this.setState({
        ...this.state,
        dropOffValues
      })
    })
  }

  handlePickUpLocationDelete = (index) => {
    let pickUpOptions = this.state.pickUpOptions;
    pickUpOptions.splice(index, 1);

    this.setState({
      ...this.state,
      pickUpOptions
    }, async () => {
      let dropOffValues = await this.preparePickUpValues()
      this.setState({
        ...this.state,
        dropOffValues
      })
    })
  }

  handleAdminEmailDelete = (index) => {
    let adminEmails = this.state.adminEmails;
    adminEmails.splice(index, 1);

    this.setState({
      ...this.state,
      adminEmails
    })
  }

  render() {
    console.log('state in settings page', this.state)
    let pickUpOptions = this.state.pickUpOptions;
    let dropOffOptions = this.state.dropOffOptions;
    let adminEmails = this.state.adminEmails;
    return (
      <div>
          {this.state.loading && (
          <Segment style={{ marginTop: '40px', height: '400px'}}>
            <Dimmer active inverted>
              <Loader inverted content='Loading' />
            </Dimmer>
          </Segment>
        )}
        {!this.state.isLoggedInUserAnAdmin && !this.state.loading && (
        <Header as='h3' style={{ textAlign: 'center', marginTop: '10%'}}>Access Unauthorized</Header>
        )}
        {this.state.isLoggedInUserAnAdmin && !this.state.loading && (
          <Segment padded='very' style={{ marginLeft: '5%', marginTop: '50px', marginRight: '5%'}}>
          <Header as='h3' style={{ textAlign: 'center', display: 'inline'}}>Settings</Header>
            <Form style={{ marginTop: '20px'}}>
              <div style={{ display: 'inline'}}>
              <Header style={{ display: 'inline'}} as='h4'>Pick Up</Header>
                <AddPickUpLocationModal style={{ display: 'inline'}}
                  parentCallback={this.addPickUpToState}/>
              </div>
              <List divided relaxed style={{ marginTop: '5px'}}>
                {pickUpOptions.length > 0 && 
                Object.keys(pickUpOptions).map(key => (
                  <List.Item key={key}>
                    <List.Icon name='map marker alternate' size='large' verticalAlign='middle' />
                    <List.Content>
                      <List.Header style={{ display: 'inline'}} as='a'>{pickUpOptions[key].text}</List.Header>
                      <Icon 
                        onClick={() => this.handlePickUpLocationDelete(key)}
                        style={{ display: 'inline', float: 'right', cursor: 'pointer'}}
                        name="minus circle" 
                        color="red"
                        size="large"
                        />
                      <List.Description as='a'>{pickUpOptions[key].address.name}</List.Description>
                    </List.Content>
                  </List.Item>
                ))}
              </List>
              <br />
              
              <div style={{ display: 'inline'}}>
              <Header style={{ display: 'inline'}} as='h4'>Drop Off</Header>
                <AddDropOffLocationModal style={{ display: 'inline'}}
                  parentCallback={this.addDropOffToState}/>
              </div>
              <List divided relaxed style={{ marginTop: '5px'}}>
                {dropOffOptions.length > 0 && 
                Object.keys(dropOffOptions).map(key => (
                  <List.Item key={key}>
                    <List.Icon name='map marker alternate' size='large' verticalAlign='middle' />
                    <List.Content>
                      <List.Header style={{ display: 'inline'}} as='a'>{dropOffOptions[key].text}</List.Header>
                      <Icon 
                        onClick={() => this.handleDropOffLocationDelete(key)}
                        style={{ display: 'inline', float: 'right', cursor: 'pointer'}}
                        name="minus circle" 
                        color="red"
                        size="large"
                        />
                      {dropOffOptions[key].address.length > 0 && (
                        dropOffOptions[key].address.map((address, index) => {
                          return (
                            <List.Description key={index}>{address.name}</List.Description>
                          )
                        }))}
                    </List.Content>
                  </List.Item>
                ))}
              </List>
              <br />

              <div style={{ display: 'inline'}}>
              <Header style={{ display: 'inline'}} as='h4'>Admin Emails</Header>
                <AddAdminEmailModal
                  style={{ display: 'inline'}}
                  parentCallback={this.addAdminEmailToState}
                />
              </div>
              <List divided relaxed style={{ marginTop: '5px'}}>
                {adminEmails.length > 0 && 
                Object.keys(adminEmails).map(key => (
                  <List.Item key={key}>
                    <List.Icon name='mail' size='large' verticalAlign='middle' />
                    <List.Content>
                      <List.Header style={{ display: 'inline'}} as='a'>{adminEmails[key]}</List.Header>
                      <Icon 
                        onClick={() => this.handleAdminEmailDelete(key)}
                        style={{ display: 'inline', float: 'right', cursor: 'pointer'}}
                        name="minus circle" 
                        color="red"
                        size="large"
                        />
                    </List.Content>
                  </List.Item>
                ))}
              </List>
              <br />
              
              <div style={{ textAlign: 'center', marginTop: '20px'}}>
                <Button size="small" primary onClick={this.updateSettings}>Save</Button>
              </div>
            </Form>
            {this.state.settingsUpdatedInDB && (
              <>
                <Modal
                  open={this.state.settingsUpdatedInDB}
                  onClose={this.handleClose}
                  closeIcon
                >
                  <Modal.Content>
                  <h2 style={{ textAlign: 'center', }}>
                      Settings updated! <Icon color="green" name="check circle" />
                  </h2>
                  </Modal.Content>
                  <Modal.Actions>
                      <Button onClick={this.handleClose}>
                        Close
                      </Button>
                  </Modal.Actions>
                </Modal>
              </>
            )}
          </Segment>
        )}
      </div>
        
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  //console.log('mapstateprops in decision page', state)
  return {
    user: state
  }
}

export default connect(mapStateToProps) (SettingsPage);
