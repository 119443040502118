import React, { Component } from "react";
import { Map, TileLayer } from "react-leaflet";
import Routing from "./RoutingMachine";
import { List } from 'semantic-ui-react';
import moment from 'moment';

export default class LeafletMap extends Component {

  componentDidMount = () => {
    console.log('props in leafletmap', this.props)
  }

  state = {
    lat: 37.65,
    lng: -122.05,
    zoom: 11,
    isMapInit: false
  };
  saveMap = map => {
    this.map = map;
    this.setState({
      isMapInit: true
    });
  };

  render() {
    const position = [this.state.lat, this.state.lng];
    return (
      <div>
          <Map center={position} zoom={this.state.zoom} ref={this.saveMap}>
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
          />
          {this.state.isMapInit && <Routing map={this.map} latLngArray={this.props.selectedAddressLatLng}/>}
        </Map>
        {this.props.selectedAddressLatLng.length > 1 && (
            <List floated="left" horizontal style={{ marginBottom: '2%'}}>
              {Object.keys(this.props.selectedAddressLatLng).map(key => (
                <List.Item>{this.props.selectedAddressLatLng[key].location},</List.Item>
              ))}
              <List.Item>- &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.props.recentConfirmedRequest && moment(this.props.recentConfirmedRequest).format("Do MMM, hh:mm A")}</List.Item>
            </List>
        )}
      </div>
    );
  }
}
