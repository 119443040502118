import React, { Component } from 'react'
import { Button, Modal, Icon, Header } from 'semantic-ui-react'

class NotSignedInModal extends React.Component {
    state = {
        modalOpen: false
    };

    componentDidMount = () => {
        this.setState({
            ...this.state,
            modalOpen: true
        })
    }

    handleOpen = () => this.setState({ modalOpen: true });

    handleClose = () => {
        this.setState({ modalOpen: false })
        this.props.parentCallback(false);
    }

    handleOK = () => {
        this.setState({
            ...this.state,
            modalOpen: false
        })
        this.props.parentCallback();
    }

    render() {
        // console.log('props in modal', this.props)
        // console.log('state in modal', this.state)
        return (
            <div>
            <Modal
                open={this.state.modalOpen}
                onClose={this.handleClose}
                closeIcon
            >
                <Modal.Header>Error</Modal.Header>
                <Modal.Content>
                <p>
                Please sign in to continue
                </p>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.handleOK}>
                        Ok
                    </Button>
                </Modal.Actions>
            </Modal>
        </div>
        )
    }
}

export default NotSignedInModal;