import { MapLayer } from "react-leaflet";
import L from "leaflet";
import "leaflet-routing-machine";
import { withLeaflet } from "react-leaflet";

class Routing extends MapLayer {
  createLeafletElement() {
    console.log('inside Routing')
    const { map } = this.props;
    console.log('latLngArray in Routing', this.props.latLngArray);
    let preparedWaypoints = [];
    for(let latLng of this.props.latLngArray) {
      let latLngEntity = L.latLng(latLng.lat, latLng.lng);
      preparedWaypoints.push(latLngEntity)
    }

    let leafletElement = L.Routing.control({
      //waypoints: [L.latLng(37.773972, -122.431297), L.latLng(37.667997328, -122.075166366)],
      waypoints: preparedWaypoints,
      routeWhileDragging: true
    }).addTo(map.leafletElement);
    return leafletElement.getPlan();
  }
}
export default withLeaflet(Routing);
