import firebase from 'firebase/app'
// import firebase from 'firebase/compat/app'; //v9
import 'firebase/auth'

var firebaseConfig = {
    apiKey: "AIzaSyBlCtJqqEHTqU4gdixpAV1KfAu-9lsQ65s",
    authDomain: "calendartest-325917.firebaseapp.com",
    projectId: "calendartest-325917",
    storageBucket: "calendartest-325917.appspot.com",
    messagingSenderId: "67906956523",
    appId: "1:67906956523:web:708f7928b188d3d288a2ae",
    measurementId: "G-WMS0Q1KQB9"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  
export default firebase
