import { Form, Label, Button, Header, Segment, Comment } from 'semantic-ui-react'
import { Component } from 'react'
import { connect } from "react-redux";
import Checklist from './functions/Checklist';
import axios from 'axios'
import moment from 'moment';

class StudentChecklistPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userAvatar: '',
      userName: '',
      userEmail: null,
      checklist: []
    }
  }

  componentDidUpdate = () => {
    if(this.state.userEmail===null && this.props.user.auth.userEmail) {
      this.setState({
        ...this.state,
        userEmail: this.props.user.auth.userEmail,
        userAvatar: this.props.user.auth.userAvatar,
        userName: this.props.user.auth.userName
      }, () => {
        this.retrieveUserChecklist();
      })
    }
  }

  retrieveUserChecklist = async () => {
    const userResponse = await axios.get(`/users/${this.state.userEmail}`);
    this.setState({
      ...this.state,
      checklist: userResponse.data.checklist
    })
  }

  render() {
    console.log('state in student check page', this.state)
    return (
      <Segment padded='very' style={{ marginLeft: '5%', marginTop: '10px', marginRight: '5%'}}>
          <Comment.Group>
              <Header as='h3' dividing>
                  Student Checklist
                  <Header.Subheader>
                      It is advisable to get these tasks out of the way within the first week you land in the US 
                  </Header.Subheader>
              </Header>
              {this.state.checklist.length>1 && (
                <Checklist checklistValue={this.state.checklist} />
              )}
          </Comment.Group>
      </Segment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state
  }
}

export default connect(mapStateToProps) (StudentChecklistPage);
