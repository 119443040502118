import axios from 'axios';
import React, { Component } from 'react'
import { connect } from "react-redux";
import { Button, Modal, Icon, Header, Form, Select } from 'semantic-ui-react'

//TO-DO Geocode api to get lat lng, 
//TO-DO google calendar has feature
// which can check if an event already exists for this time, let Leon know about existing events for the request date
class AcceptDecisionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      requestId: null,
      suggestion: "",
      request: {},
      preparedLocationAddressFromRequest: [],
      selectedDropOffAddresses: [],
      error: false,
      request_decision_author_email: "",
      request_decision_author_name: ""
    };
  }

    componentDidMount = () => {
      this.setState({
          ...this.state,
          modalOpen: false
      })
    }

    componentWillReceiveProps = () => {
      console.log('inside componentWillReceiveProps', this.props)
      this.setState({
        ...this.state,
        requestId: this.props.requestId,
        request: this.props.request,
        preparedLocationAddressFromRequest: this.props.request.prepared_location_address,
        request_decision_author_email: this.props.user.auth.userEmail,
        request_decision_author_name: this.props.user.auth.userName
      }, () => {
        this.transformPreparedLocAddr();
      })
    }

    transformPreparedLocAddr = () => {
      let preparedLocationAddressFromRequest = this.state.preparedLocationAddressFromRequest;
      for (let location of preparedLocationAddressFromRequest) {
        for (let address of location.address) {
          address.text = address.name;
          address.value = address.name;
          address.key = address.name.split(' ').map(x => x[0]).join('');
        }
      }
      //console.log('transformedArray', preparedLocationAddressFromRequest);
      this.setState({
        ...this.state,
        preparedLocationAddressFromRequest
      })
    }

    handleClose = () => {
        this.setState({ modalOpen: false })
        this.props.parentCallback();
    }

    handleModalOpen = () => {
      console.log('inside handle open')
      this.setState({ 
        modalOpen: true
      });
    }

    handleInputChange = (e) => {
        console.log(e.target.name)
        console.log(e.target.value)
        this.setState({
          ...this.state,
          [e.target.name]: e.target.value
        })
    }

    handleDecisionAcceptance = async() => {
      //await this.insertEventIntoCalendar();
      if(this.state.preparedLocationAddressFromRequest.length>0 && this.state.selectedDropOffAddresses.length===0) {
        this.setState({
          ...this.state,
          error: true
        })
      } else {
        let requestObject = {
          requestId: this.state.requestId,
          suggestion: this.state.suggestion,
          selectedDropOffAddresses: this.state.selectedDropOffAddresses,
          request_decision_author_name: this.state.request_decision_author_name,
          request_decision_author_email: this.state.request_decision_author_email
        }
        const response = await axios.post('/request/accept', requestObject);
        console.log('response from accept', response)
        this.setState({ modalOpen: false })
        this.props.parentCallback();
      }
    }

    // insertEventIntoCalendar = async() => {
    //     var gapi = window.gapi;
    //     console.log('gapi', gapi)
    //     var CLIENT_ID = "67906956523-tg19i31knr205lpjprhgmlg4fcc6qteg.apps.googleusercontent.com"
    //     var API_KEY = "AIzaSyClIn6nTgZ0dn4LOJf3QF1ZuiDODxPkDVc"
    //     var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"];
    //     var SCOPES = "https://www.googleapis.com/auth/calendar.events"


    //     await gapi.load('client:auth2', async() => {
    //         console.log("loaded client")
      
    //         gapi.client.init({
    //           apiKey: API_KEY,
    //           clientId: CLIENT_ID,
    //           discoveryDocs: DISCOVERY_DOCS,
    //           scope: SCOPES
    //         })
      
    //         await gapi.client.load('calendar', 'v3', () => console.log('loaded calendar'))
    //         await gapi.auth2.getAuthInstance().signIn()
    //         .then(() => {
    //           console.log('signed in')
    //           var event = {
    //             'summary': 'Google I/O 2015',
    //             'location': '800 Howard St., San Francisco, CA 94103',
    //             'description': 'A chance to hear more about Google\'s developer products.',
    //             'start': {
    //               'dateTime': '2021-10-10T09:00:00-07:00',
    //               'timeZone': 'America/Los_Angeles'
    //             },
    //             'end': {
    //               'dateTime': '2021-10-10T17:00:00-07:00',
    //               'timeZone': 'America/Los_Angeles'
    //             },
    //             'recurrence': [
    //               'RRULE:FREQ=DAILY;COUNT=2'
    //             ],
    //             'attendees': [
    //               {'email': 'lpage@example.com'},
    //               {'email': 'sbrin@example.com'}
    //             ],
    //             'reminders': {
    //               'useDefault': false,
    //               'overrides': [
    //                 {'method': 'email', 'minutes': 24 * 60},
    //                 {'method': 'popup', 'minutes': 10}
    //               ]
    //             }
    //           };
      
    //           var request = gapi.client.calendar.events.insert({
    //             'calendarId': 'primary',
    //             'resource': event
    //           })
      
    //           request.execute(event => {
    //             console.log('event after request execute', event)
    //             window.open(event.htmlLink)
    //           })
    //         })
    //     })
    // }

    handleChange = (loc, addressObject) => (event, data) => {
      console.log('addressObject', addressObject)
      console.log('loc', loc);
      console.log('addr selected', data.value);

      let selectedDropOffAddresses = this.state.selectedDropOffAddresses;
      let selectedAddressObject = {}
      selectedAddressObject.location = loc;

      for(let addr of addressObject) {
        if(addr.name === data.value) {
          selectedAddressObject.address = addr.name;
          selectedAddressObject.lat = addr.lat;
          selectedAddressObject.lng = addr.lng;
        }
      }

      selectedDropOffAddresses.push(selectedAddressObject);
      this.setState({
        ...this.state,
        selectedDropOffAddresses
      })
    }

    render() {
        //console.log('props in accept decision modal', this.props)
        console.log('state in accpt decs modal', this.state)
        return (
            <div>
                <Modal
                    closeIcon
                    open={this.props.show}
                    onClose={this.handleClose}
                    onOpen={this.handleModalOpen}
                    >
                    <Header content='Confirm decision?' />
                    <Modal.Content>
                        <p>
                            Are you sure you want to accept this request?
                        </p>
                        <Form>
                        {this.state.preparedLocationAddressFromRequest.length > 0 && 
                          Object.keys(this.state.preparedLocationAddressFromRequest).map(location => (
                            <Form.Field error={this.state.error}>
                              <label>{this.state.preparedLocationAddressFromRequest[location].name} - Address</label>
                              <Select
                                name = 'dropoff'
                                placeholder='Select address for drop off locations' 
                                options={this.state.preparedLocationAddressFromRequest[location].address} 
                                onChange={this.handleChange(
                                  this.state.preparedLocationAddressFromRequest[location].name,
                                  this.state.preparedLocationAddressFromRequest[location].address
                                )}
                              />
                            </Form.Field>
                          ))}
                          <Form.TextArea
                            name="suggestion"
                            onChange={this.handleInputChange}
                            label='Suggestions' 
                            placeholder='Enter suggestions for venue and/or if you want to recommend a different time on the same day' />
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='red' onClick={this.handleClose}>
                            <Icon name='remove' /> No
                        </Button>
                        <Button color='green' onClick={this.handleDecisionAcceptance}>
                            <Icon name='checkmark' /> Yes
                        </Button>
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
  console.log('mapstateprops in accept decision modal', state)
  return {
    user: state
  }
}

export default connect(mapStateToProps)(AcceptDecisionModal);