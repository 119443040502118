import React, {Component} from "react";
import PageGrid from "./components/PageGrid";
import DriverDecisionPage from "./components/DriverDecisionPage";
import DecisionsPage from "./components/DecisionsPage";
import SettingsPage from "./components/SettingsPage";
import TestimonialsPage from "./components/TestimonialsPage";
import StudentChecklistPage from "./components/StudentChecklistPage";
import Header from "./components/Header";
import { Router, Route, Switch } from 'react-router-dom';
import history from '../src/history';

import './index.css'

class App extends Component {
  constructor(props) {
    super(props);
  }

  render () {
    return (
      <div id='home'>
        <Router history={history}>
          <Header/>
          <div>
            <Switch>
              <Route path="/" exact component={PageGrid}/>
              <Route path="/driver_decision/:id" exact component={DriverDecisionPage}/>
              <Route path="/decisions" exact component={DecisionsPage} />
              <Route path="/settings" exact component={SettingsPage} />
              <Route path="/testimonials" exact component={TestimonialsPage} />
              <Route path="/checklist" exact component={StudentChecklistPage} />
            </Switch>
          </div>
        </Router>
      </div>
    )
  }
}

export default App;
