import { Label, Form, Input, TextArea, Button, Segment, Header, Image, Divider } from 'semantic-ui-react'
import { Component } from 'react'
import moment from 'moment'
import { connect } from "react-redux";
import { Link } from 'react-router-dom'
import axios from 'axios'
import AcceptDecisionModal from './modals/AcceptDecisionModal';
import RejectDecisionModal from './modals/RejectDecisionModal';

class DriverDecisionPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestFetched: {},
      requestId: '',
      showAcceptModal: false,
      showRejectModal: false,
      userName: '',
      userEmail: '',
      residence: '',
      building_number: '',
      appartment_number: '',
      address: '',
      phone_number: ''
    }
  }

  async componentWillMount() {
    let recordID = this.props.match.params.id;
    await this.fetchRequest(recordID);
    this.setState({
      ...this.state,
      requestId: recordID
    })
  }

  fetchRequest = async(requestId) => {
    console.log('inside fetch request', requestId)
    let response = await axios.get(`/request/fetch/${requestId}`)
    console.log('response', response)
    this.setState({
      ...this.state,
      requestFetched: response.data
    })
    await this.fetchUser(response.data.user_email);
  }

  fetchUser = async(email) => {
    const response = await axios.get(`/users/${email}`);
    console.log('response from user fetch', response)
    if(response.data) {
      this.setState({
        ...this.state,
        userName: response.data.name,
        userEmail: email,
        residence: response.data.residence,
        building_number: response.data.building_number,
        appartment_number: response.data.apartment_number,
        address: response.data.complete_address,
        phone_number: response.data.phone
      })
    }
  }

  callbackModal = () => {
    this.setState({ 
      ...this.state,
      showAcceptModal: false,
      showRejectModal: false
    });
    window.location.reload();
  }

  render() {
      console.log('state in driver decision page', this.state);
      let requestFetched = this.state.requestFetched;
      let isMobile = false; //initiate as false
      // device detection
      if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
          || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) { 
          isMobile = true;
      }
    return (
      <div>
        {!isMobile && (
          <Segment padded='very' style={{ width: '700px', marginLeft: '20%', marginTop: '50px'}}>
            <Header as='h3' style={{ textAlign: 'center', display: 'inline'}}>Request</Header>
              <Button as={Link} onClick={() => {window.location.href="/decisions"}} primary style={{ display: 'inline', float: 'right'}} >
                  All Requests
              </Button>
              <Form style={{ marginTop: '20px'}}>
                  <Form.Field inline/>
                      <label><b>User</b></label>
                      <Input
                        style={{marginLeft: '20px', width: '40%'}}
                        readOnly
                        value={requestFetched.user_name}
                      />
                      <label style={{marginLeft: '40px'}}><b>Phone</b></label>
                      <Input
                        style={{marginLeft: '10px', width: '25%'}}
                        readOnly
                        value={this.state.phone_number}
                      />
                  <Form.Field />
                  <Form.Field inline />
                      <label ><b>From</b></label>
                      <Input
                        style={{marginLeft: '20px', width: '35%'}}
                        readOnly
                        value={requestFetched.pick_up}
                      />
                      <label style={{marginLeft: '20px'}}><b>Apartment</b></label>
                      <Input
                        style={{marginLeft: '15px', width: '10%'}}
                        readOnly
                        value={this.state.appartment_number}
                      />
                      <label style={{marginLeft: '20px'}}><b>Building</b></label>
                      <Input
                        style={{marginLeft: '15px', width: '10%'}}
                        readOnly
                        value={this.state.building_number}
                      />
                  <Form.Field />

                  <Form.Field inline/>
                      <label><b>Address</b></label>
                      <Input
                        style={{marginLeft: '10px', width: '80%'}}
                        readOnly
                        value={this.state.address}
                      />
                  <Form.Field />
                  
                  <Form.Field inline/>
                      <label><b>To</b></label>
                      <Input
                        style={{marginLeft: '40px', width: '80%'}}
                        readOnly
                        value={requestFetched.drop_off}
                      />
                  <Form.Field />

                  <Form.Group widths='equal'>
                      <Form.Field>
                          <label>Capacity</label>
                          <Input fluid value={requestFetched.capacity} readOnly/>
                      </Form.Field>
                      <Form.Field>
                          <label>Date</label>
                          <Input fluid value={moment(requestFetched.request_date).format("Do MMM , hh:mm A")} readOnly/>
                      </Form.Field>
                      <Form.Field>
                          <label>Trailer required?</label>
                          <Input fluid value={requestFetched.hitch_required} readOnly/>
                      </Form.Field>
                  </Form.Group>

                  {requestFetched.personalized_instruction && (
                    <div>
                      <Form.Field label="Personalized instructions" />
                      <TextArea
                        readOnly
                        style={{ minHeight: 10, width: '100%', marginTop: '-10px' }} 
                        value={requestFetched.personalized_instruction}
                      />
                    </div>
                  )}
                  {requestFetched.comments && (
                    <div>
                      <Form.Field label="Comments from driver" />
                      <TextArea
                        style={{ minHeight: 10, width: '100%', marginTop: '-10px' }} 
                        readOnly
                        value={requestFetched.comments}
                      />
                    </div>
                  )}
                      <Divider />
                    {requestFetched.image_urls && requestFetched.image_urls.length>0 && Object.keys(requestFetched.image_urls).map(key => (
                      <div>
                        <Form.Field label="Attached images" />
                        <Image src={requestFetched.image_urls[key]}/>
                        <Divider />
                      </div>
                    ))}
              </Form>
              {requestFetched.request_status==='pending' && (
                  <div style={{ textAlign: 'center', marginTop: '20px'}}>
                    <Button color='green' onClick={() => this.setState({ showAcceptModal: true })}>Accept</Button>
                    <Button color='red' onClick={() => this.setState({ showRejectModal: true })}>Reject</Button>
                    <AcceptDecisionModal
                      requestId={this.state.requestId}
                      show={this.state.showAcceptModal} 
                      parentCallback={this.callbackModal}
                      request={this.state.requestFetched}
                    />
                    <RejectDecisionModal
                      requestId={this.state.requestId}
                      show={this.state.showRejectModal} 
                      parentCallback={this.callbackModal} 
                    />
                  </div>
              )}
        </Segment>
        )}
        {isMobile && (
          <div style={{ marginTop: '20px', marginLeft: '3%', marginRight: '3%'}}>
            <Header as='h3' style={{ textAlign: 'center', display: 'inline'}}>Request</Header>
              <Button as={Link} onClick={() => {window.location.href="/decisions"}} primary style={{ display: 'inline', float: 'right'}} >
                  All Requests
              </Button>
            <Form style={{ marginTop: '20px'}}>
            <Form.Field inline/>
              <label><b>User</b></label>
              <Input
                style={{marginLeft: '20px', width: '40%'}}
                readOnly
                value={requestFetched.user_name}
              />
            <Form.Field />
            <Form.Field inline>
              <label><b>Phone</b></label>
                <Input
                  style={{width: '40%'}}
                  readOnly
                  value={this.state.phone_number}
                />
            </Form.Field>

            <Form.Field inline />
                <label ><b>From</b></label>
                <Input
                  style={{marginLeft: '15px', width: '60%'}}
                  readOnly
                  value={requestFetched.pick_up}
                />
            <Form.Field />
            <Form.Field inline>
              <label><b>Apartment</b></label>
                <Input
                  style={{width: '20%'}}
                  readOnly
                  value={this.state.appartment_number}
                />
                <label style={{marginLeft: '25px'}}><b>Building</b></label>
                <Input
                  style={{marginLeft: '15px', width: '20%'}}
                  readOnly
                  value={this.state.building_number}
            />
            </Form.Field>

            <Form.Field inline/>
              <label><b>Address</b></label>
              <Input
                style={{marginLeft: '10px', width: '80%'}}
                readOnly
                value={this.state.address}
              />
          <Form.Field />

            <Form.Field inline/>
                <label><b>To</b></label>
                <Input
                  style={{marginLeft: '30px', width: '80%'}}
                  readOnly
                  value={requestFetched.drop_off}
                />
            <Form.Field />

            <Form.Field inline>
              <label><b>Capacity</b></label>
                <Input
                  style={{width: '15%'}}
                  readOnly
                  value={requestFetched.capacity}
                />
                <label style={{marginLeft: '30px'}}><b>Date</b></label>
                <Input
                  style={{marginLeft: '15px', width: '45%'}}
                  readOnly
                  value={moment(requestFetched.request_date).format("Do MMM , hh:mm A")}
                />
            </Form.Field>

            <Form.Field inline>
              <label>Trailer required?</label>
              <Label>
                {`${requestFetched.hitch_required}`}
              </Label>
            </Form.Field>

            {requestFetched.personalized_instruction && (
                <div>
                  <Form.Field label="Personalized instructions" />
                  <TextArea
                    readOnly
                    style={{ minHeight: 10, width: '100%', marginTop: '-10px' }} 
                    value={requestFetched.personalized_instruction}
                  />
                </div>
              )}
              {requestFetched.comments && (
                <div>
                  <Form.Field label="Comments from driver" />
                  <TextArea
                    style={{ minHeight: 10, width: '100%', marginTop: '-10px' }} 
                    readOnly
                    value={requestFetched.comments}
                  />
                </div>
              )}
              <Divider />
                {requestFetched.image_urls && requestFetched.image_urls.length>0 && Object.keys(requestFetched.image_urls).map(key => (
                  <div>
                    <Form.Field label="Attached images" />
                    <Image src={requestFetched.image_urls[key]}/>
                    <Divider />
                  </div>
                ))}
        </Form>
        {requestFetched.request_status==='pending' && (
          <div style={{ textAlign: 'center', marginTop: '20px'}}>
            <Button color='green' onClick={() => this.setState({ showAcceptModal: true })}>Accept</Button>
            <Button color='red' onClick={() => this.setState({ showRejectModal: true })}>Reject</Button>
            <AcceptDecisionModal
              requestId={this.state.requestId}
              show={this.state.showAcceptModal} 
              parentCallback={this.callbackModal}
              request={this.state.requestFetched}
            />
            <RejectDecisionModal
              requestId={this.state.requestId}
              show={this.state.showRejectModal} 
              parentCallback={this.callbackModal} 
            />
          </div>
          )}
        </div>
        )}
      </div>
        
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {user: state}
}

export default connect(mapStateToProps)(DriverDecisionPage);
