import { Table, Header, Dropdown, Label, Pagination, Form, Button, Loader, Segment, Dimmer, Image } from 'semantic-ui-react'
import { Component } from 'react'
import moment from 'moment'
import { connect } from "react-redux";
import axios from 'axios'
import DriverDecisionModal from './modals/DriverDecisionModal';

//TO-DO: Add action buttons for every row, giving option to cancel a confirmed request
//action buttons to also accept or reject request
//Websocket chat functionality
//TO-DO 
class DecisionsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestsFetched: [],
      userOptions: [],
      requestFromDateSelected: null,
      requestToDateSelected: null,
      pickUpSelected: "",
      userSelected: "",
      requestStatusSelected: "",
      activePage: 1,
      totalPages: 1,
      openDriverDecisionModal: false,
      pickUpOptions: [],
      dropOffOptions: [],
      adminEmails: [],
      loggedInUserEmail: null,
      isLoggedInUserAnAdmin: false,
      loading: true
    }
  }

  async componentWillMount () {
    console.log('inside component will mount', this.props)
    await this.fetchSettings();
    let users = await this.fetchUsers();
    users = users.data;

    let userOptions = []
    for (let user of users) {
      console.log('user', user)
      let object = {
        key: user._id,
        value: user.emailId,
        text: user.name
      }
      userOptions.push(object)
    }
    this.setState({
      ...this.state,
      userOptions
    })


    let filterRequestObject = await this.prepareFilterRequestObject()
    await this.fetchFilterRequests({ 
      request: filterRequestObject,
      currentPage: this.state.activePage
    });
  }

  fetchSettings = async() => {
    //console.log('inside fetch settings', this.props)
    const response = await axios.get('/settings_route', {});
    this.setState({
      ...this.state,
      pickUpOptions: response.data.response.pick_up_locations,
      dropOffOptions: response.data.response.drop_off_locations,
      adminEmails: response.data.response.admin_emails
    })
  }

  fetchUsers = async() => {
    let usersResponse = await axios.get(`/users/all`)
    return usersResponse;
  }

  componentDidMount = () => {
    console.log('inside component did mount decisions page', this.props)
    const currentDate = moment().subtract(15, 'days').format('YYYY-MM-DD');
    const currentTime = moment().subtract(15, 'days').format('HH:mm')
    let currentDateTime = `${currentDate}T${currentTime}`
    currentDateTime = currentDateTime.split(' ')[0];

    const nextDayDate = moment().add(30, 'days').format('YYYY-MM-DD');
    const nextDayTime = moment().format('HH:mm')
    let nextDayDateTime = `${nextDayDate}T${nextDayTime}`
    nextDayDateTime = nextDayDateTime.split(' ')[0];

    //console.log('nextDayDate', nextDayDate)

    this.setState({
      ...this.state,
      requestFromDateSelected: currentDateTime,
      requestToDateSelected: nextDayDateTime
    })
  }

  componentDidUpdate = () => {
    // console.log('value in props', this.props.user.auth.userEmail)
    // console.log('value in state', this.state.loggedInUserEmail)
    if(this.state.loggedInUserEmail===null && this.props.user.auth.userEmail) {
      console.log('loggedinemail is empty')
      let isLoggedInUserAnAdmin;
      if(this.state.adminEmails.includes(this.props.user.auth.userEmail)) {
        isLoggedInUserAnAdmin = true
      } else {
        isLoggedInUserAnAdmin = false
      }
      this.setState({
        ...this.state,
        loading: false,
        loggedInUserEmail: this.props.user.auth.userEmail,
        isLoggedInUserAnAdmin
      })
    }
  }

  fetchFilterRequests = async(params) => {
    console.log('inside fetch filter request')
    let filterRequestResponse = await axios.get(`/filter`, {params})
    console.log('filterRequestResponse', filterRequestResponse)
    this.setState({
      ...this.state,
      requestsFetched: filterRequestResponse.data.response.data,
      totalPages: filterRequestResponse.data.response.total
    })
  }

  cleanDate(date) {
    var cleanDateFormat = "";
    if (date) {
      cleanDateFormat = moment(date).format("Do MMM, hh:mm A");
    }
    return cleanDateFormat;
  }

  handleInputChange = (e) => {
    console.log(e.target.name)
    console.log(e.target.value)
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
      error: false
    })
  }

  handleChange = (e, data) => {
    this.setState({
      ...this.state,
      [data.name]: data.value
    })
  }

  clearFilters = async() => {
    const currentDate = moment().format('YYYY-MM-DD');
    const currentTime = moment().format('HH:mm')
    let currentDateTime = `${currentDate}T${currentTime}`
    currentDateTime = currentDateTime.split(' ')[0];
    this.setState({
      ...this.state,
      pickUpSelected: "",
      userSelected: "",
      requestStatusSelected: "",
      requestFromDateSelected: currentDateTime,
      requestToDateSelected: currentDateTime,
      activePage: 1
    }, async() => {
      let filterRequestObject = await this.prepareFilterRequestObject();
      await this.fetchFilterRequests({ 
        request: filterRequestObject,
        currentPage: this.state.activePage
      });
    })
  }

  prepareFilterRequestObject = () => {
    let filterRequestObject = {
      request_date: {}
    }
    if(this.state.pickUpSelected) {
      filterRequestObject.pick_up = this.state.pickUpSelected
    }
    if(this.state.userSelected) {
      filterRequestObject.user_email = this.state.userSelected
    }
    if(this.state.requestStatusSelected) {
      filterRequestObject.request_status = this.state.requestStatusSelected
    }
    //date querying in mongo db
    if(this.state.requestFromDateSelected) {
      filterRequestObject.request_date.$gte = new Date(`${this.state.requestFromDateSelected}:00Z`)
    }
    if(this.state.requestToDateSelected) {
      filterRequestObject.request_date.$lte = new Date(`${this.state.requestToDateSelected}:00Z`)
    }

    console.log('filterRequestObject', filterRequestObject)
    return filterRequestObject;
  }

  filterRequests = async () => {
    //get requests but prepare an object
    let filterRequestObject = await this.prepareFilterRequestObject();
    
    const params = {
      request : filterRequestObject,
      currentPage: this.state.activePage
    }
    await this.fetchFilterRequests(params);
  }

  handlePaginationChange = async (e, { activePage }) => {
    console.log('activePage in handlepagination change', activePage)
    this.setState({ activePage })

    let filterRequestObject = await this.prepareFilterRequestObject();
    const params = {
      request : filterRequestObject,
      currentPage: activePage
    }
    await this.fetchFilterRequests(params);
  }

  openDriverDecisionModal = () => {
    this.setState({
      ...this.state,
      openDriverDecisionModal: true
    })
  }

  render() {
    console.log('state in decisions page', this.state)
    const requests = this.state.requestsFetched;

    const currentDate = moment().format('YYYY-MM-DD');
    const currentTime = moment().format('HH:mm')
    const currentDateTime = `${currentDate}T${currentTime}`
    console.log('currentDateTime', currentDateTime)

    let isMobile = false; //initiate as false
    if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
      || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) { 
      isMobile = true;
    }

    const requestStatusOptions = [
      { key:'pen', value:'pending', text: 'Pending'},
      { key:'con', value:'confirmed', text: 'Confirmed'},
      { key:'dec', value:'declined', text: 'Declined'},
      { key:'can', value:'cancelled', text: 'Cancelled'},
      { key:'com', value:'completed', text: 'Completed'}
    ]

    return (
      <div>
        {this.state.loading && (
          <Segment style={{ marginTop: '40px', height: '400px'}}>
            <Dimmer active inverted>
              <Loader inverted content='Loading' />
            </Dimmer>
          </Segment>
        )}
        {!this.state.isLoggedInUserAnAdmin && !this.state.loading && (
        <Header as='h3' style={{ textAlign: 'center', marginTop: '10%'}}>Access Unauthorized</Header>
        )}
        {this.state.isLoggedInUserAnAdmin && !this.state.loading && (
            <div style={{ marginLeft: '2%', marginRight: '2%', marginTop: '20px' }}>
            {!isMobile && (<Form>
              <Form.Field inline>
                <Header as='h3' dividing style={{ textAlign: 'center', display: 'inline'}}>Requests</Header>
                {this.state.requestsFetched.length>0 && (<Pagination 
                  defaultActivePage={this.state.activePage} 
                  totalPages={this.state.totalPages} 
                  style={{ marginLeft: '61%'}}
                  onPageChange={this.handlePaginationChange}
                />)}
              </Form.Field>
            </Form>)}
            {isMobile && this.state.requestsFetched.length>0 && (
              <div style={{ textAlign: 'center'}}>
                <Pagination 
                  defaultActivePage={this.state.activePage} 
                  totalPages={this.state.totalPages} 
                  onPageChange={this.handlePaginationChange} 
                />
              </div>
            )}
            <Form style={{ marginTop: '1%'}}>
              <Form.Group widths='equal'>
                <Form.Field width={1}>
                  <label>From</label>
                  <Dropdown
                    clearable
                    name="pickUpSelected"
                    placeholder='begin typing'
                    fluid
                    search
                    selection
                    options={this.state.pickUpOptions}
                    onChange={this.handleChange}
                    value={this.state.pickUpSelected}
                  />
                </Form.Field>
                <Form.Field width={1}>
                  <label>Users</label>
                  <Dropdown
                    clearable
                    name="userSelected"
                    placeholder='begin typing'
                    fluid
                    search
                    selection
                    options={this.state.userOptions}
                    onChange={this.handleChange}
                    value={this.state.userSelected}
                  />
                </Form.Field>
                <Form.Field width={2}>
                  <label>From Date</label>
                    <input type="datetime-local" id="fromDate"
                  name="requestFromDateSelected" 
                  value={this.state.requestFromDateSelected} 
                  onChange={this.handleInputChange}/>
                </Form.Field>
                <Form.Field width={2}>
                  <label>To Date</label>
                    <input type="datetime-local" id="toDate"
                  name="requestToDateSelected" 
                  value={this.state.requestToDateSelected} 
                  onChange={this.handleInputChange}/>
                </Form.Field>
                <Form.Field width={1}>
                  <label>Status</label>
                  <Dropdown
                    placeholder='begin typing'
                    fluid
                    search
                    selection
                    clearable
                    options={requestStatusOptions}
                    name="requestStatusSelected"
                    onChange={this.handleChange}
                    value={this.state.requestStatusSelected}
                  />
                </Form.Field>
                <Form.Field width={1} style={{ marginTop: '23px', marginLeft: '10px'}}>
                  <Button primary size='small' onClick={this.filterRequests}>Search</Button>
                  <Button style={{ marginLeft: '10px'}} secondary size='small' onClick={this.clearFilters}>Clear</Button>
                </Form.Field>
              </Form.Group>
            </Form>
            <Table unstackable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell textAlign="center">Name</Table.HeaderCell>
                    {!isMobile && (<Table.HeaderCell>From</Table.HeaderCell>)}
                    {!isMobile && (<Table.HeaderCell>To</Table.HeaderCell>)}
                    <Table.HeaderCell >Date</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Passengers</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Status</Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>Action</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
    
                <Table.Body>
                  {requests.length === 0 && (
                    <Table.Row>
                      <Table.Cell colspan="7">
                          <center>No requests available for this time frame</center>
                      </Table.Cell>
                    </Table.Row>
                  )}
                  {requests.length > 0 && 
                    Object.keys(requests).map(key => (
                      <Table.Row key={key}>
                          <Table.Cell>
                            <Label ribbon>{requests[key].request_decision_author_name.split(' ')[0]}</Label>
                            {requests[key].user_name}
                          </Table.Cell>
                          {!isMobile && (<Table.Cell>
                            {requests[key].pick_up}
                          </Table.Cell>)}
                          {!isMobile && (<Table.Cell>
                            {requests[key].drop_off.join(", ")}
                          </Table.Cell>)}
                          <Table.Cell>
                            {this.cleanDate(requests[key].request_date)}
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            {requests[key].capacity}
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            {requests[key].request_status==='pending' && (
                              <Label color='yellow'>
                                {requests[key].request_status}
                              </Label>
                            )}
                            {requests[key].request_status==='confirmed' && (
                              <Label color='green'>
                                {requests[key].request_status}
                              </Label>
                            )}
                            {requests[key].request_status==='declined' && (
                              <Label color='red'>
                                {requests[key].request_status}
                              </Label>
                            )}
                            {requests[key].request_status==='cancelled' && (
                              <Label color='grey'>
                                {requests[key].request_status}
                              </Label>
                            )}
                            {requests[key].request_status==='completed' && (
                              <Label color='black'>
                                {requests[key].request_status}
                              </Label>
                            )}
                          </Table.Cell>
                          <Table.Cell textAlign='center'>
                            <DriverDecisionModal request={requests[key]} parentCallback={this.filterRequests}/>
                          </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
                {isMobile && this.state.requestsFetched.length>0 && (
                  <div style={{ textAlign: 'center'}}>
                    <Pagination 
                      defaultActivePage={this.state.activePage} 
                      totalPages={this.state.totalPages} 
                      onPageChange={this.handlePaginationChange}
                      style={{marginBottom: '25px'}}
                    />
                  </div>
                )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  //console.log('mapstateprops in decision page', state)
  return {
    user: state
  }
}

export default connect(mapStateToProps)(DecisionsPage);
