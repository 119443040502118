import axios from 'axios';
import React, { Component } from 'react'
import { Button, Modal, Icon, Header, Form } from 'semantic-ui-react'
import { connect } from "react-redux";

class CancelDecisionModal extends React.Component {
    state = {
        modalOpen: false,
        requestId: null,
        reason: "",
        request_decision_author_email: "",
        request_decision_author_name: "",
        error: false
    };

    componentDidMount = () => {
        this.setState({
            ...this.state,
            modalOpen: false
        })
    }

    componentWillReceiveProps = () => {
        this.setState({
            ...this.state,
            requestId: this.props.requestId,
            request_decision_author_email: this.props.user.auth.userEmail,
            request_decision_author_name: this.props.user.auth.userName
        })
    }

    handleClose = () => {
        this.setState({ modalOpen: false })
        this.props.parentCallback();
    }

    handleOpen = () => this.setState({ modalOpen: true });

    handleInputChange = (e) => {
        console.log(e.target.name)
        console.log(e.target.value)
        this.setState({
          ...this.state,
          [e.target.name]: e.target.value,
          error: false
        })
    }

    handleDecisionCancellation = async() => {
        console.log('inside handleDecisionCancellation')
        if(!this.state.reason) {
            this.setState({
                ...this.state,
                error: true
            })
        } else {
            let requestObject = {
                requestId: this.state.requestId,
                reason: this.state.reason,
                request_decision_author_name: this.state.request_decision_author_name,
                request_decision_author_email: this.state.request_decision_author_email
            }
            const response = await axios.post('/request/cancel', requestObject);
            console.log('response from accept', response)
            this.setState({ modalOpen: false })
            this.props.parentCallback();
        }
    }

    render() {
        // console.log('props in modal', this.props)
        console.log('state in cancel decs modal', this.state)
        return (
            <div>
                <Modal
                    closeIcon
                    open={this.props.show}
                    onClose={this.handleClose}
                    >
                    <Header content='Confirm decision?' />
                    <Modal.Content>
                        <p>
                            Are you sure you want to cancel this confirmed request?
                        </p>
                        <Form>
                            <Form.TextArea
                                error={this.state.error}
                                name="reason"
                                onChange={this.handleInputChange}
                                label='Reason' 
                                placeholder='Enter the reason for cancelling this request' />
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='red' onClick={this.handleClose}>
                            <Icon name='remove' /> No
                        </Button>
                        <Button color='green' onClick={this.handleDecisionCancellation}>
                            <Icon name='checkmark' /> Yes
                        </Button>
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    console.log('mapstateprops in cancel decision modal', state)
    return {
      user: state
    }
  }
  
  export default connect(mapStateToProps)(CancelDecisionModal);